import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const REPORT_CAMPAIGN_AUTOCOMPLETE_QUERY = gql`
  query ReportStreamAutoCompleteQuery {
    reportStreamAutoComplete {
      streams {
        id
        uid
        title
        user {
          id
        }
      }
      targets {
        type
        values
      }
      users {
        id
        email
      }
    }
  }
`

export function useReportStreamAutocompleteQuery() {
  const { loading, error, data, refetch } = useQuery(REPORT_CAMPAIGN_AUTOCOMPLETE_QUERY, {
    variables: {},
  })
  return { loading, error, data, refetch }
}
