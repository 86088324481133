import { useIsUserInAuditoryQuery } from '@pushflow/server/src/graphql/new/userAuditories/isUserInAuditory/operations'
import { useAddUserToAuditoryMutation } from '@pushflow/server/src/graphql/new/userAuditories/addUserInAuditory/operations'
import { useDeleteUserFromAuditoryMutation } from '@pushflow/server/src/graphql/new/userAuditories/deleteUserFromAuditory/operations'
import React, { useState } from 'react'
import { Modal, Button, Input, Form } from 'antd'
import showNotification from '../../helpers/showNotification'

export const UserAuditoryActions = ({ auditoryId, onChange }) => {
  const [streamUserId, setStreamUserId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data: isInListData, refetch: refetchIsInList } = useIsUserInAuditoryQuery({
    stream: streamUserId.split(':')[0] || '',
    userId: streamUserId.split(':')[1] || '',
    auditoryId,
  })

  const [addUserToList] = useAddUserToAuditoryMutation()
  const [deleteUserFromList] = useDeleteUserFromAuditoryMutation()

  const handleCheck = () => {
    refetchIsInList().then(r => {
      showNotification({
        message: `User is ${r.data.isUserInAuditory.isInAuditory ? 'in' : 'not in'} the list`,
        type: r.data.isUserInAuditory.isInAuditory ? 'success' : 'error',
      })
    })
  }

  const handleAdd = () => {
    addUserToList({
      variables: {
        input: {
          stream: streamUserId.split(':')[0],
          auditoryId,
          userId: streamUserId.split(':')[1],
        },
      },
    }).then(() => {
      showNotification({
        message: `User added to the list`,
        type: 'success',
      })
    })

    onChange()
  }

  const handleDelete = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    deleteUserFromList({
      variables: {
        input: {
          stream: streamUserId.split(':')[0],
          auditoryId,
          userId: streamUserId.split(':')[1],
        },
      },
    }).then(r => {
      if (r.data && r.data.deleteUserFromAuditory.success) {
        showNotification({
          message: `User deleted from the list`,
          type: 'success',
        })
      }
    })
    setIsModalVisible(false)
    onChange()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div className="UserAuditoryActions" style={{ 'margin-top': '25px', 'margin-bottom': '25px' }}>
      <Form layout="inline">
        <Form.Item>
          <Input placeholder="stream:userId" value={streamUserId} onChange={e => setStreamUserId(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleCheck}>
            Check if in list
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleAdd}>
            Add to current list
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" danger onClick={handleDelete}>
            Delete from list
          </Button>
        </Form.Item>
      </Form>
      <Modal title="Confirmation" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        Are you sure you want to delete this user from the list?
      </Modal>
      {isInListData && <div>User is {isInListData.isUserInAuditory.isInAuditory ? 'in' : 'not in'} the list</div>}
    </div>
  )
}
