import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
} from 'antd'
import { QuestionCircleOutlined, StopOutlined } from '@ant-design/icons'
import showNotification from '../../../helpers/showNotification'
import { HistChart } from '../../../components/BarChart'
import { useAppState } from '../../../context/AppContext'
import { TRAFFIC_BLOCKERS_INTERVALS } from '@pushflow/backend-http-contract/dist/traffic-blockers'
import { CampaignType } from '@pushflow/backend-http-contract'

interface BlockersTableData {
  blockerName: string
  count: number
  percentage: string | number
}

type IntervalType = (typeof TRAFFIC_BLOCKERS_INTERVALS)[keyof typeof TRAFFIC_BLOCKERS_INTERVALS]

export const TrafficBlockersModal: FC<{
  campaignId: number
  stream?: string
  substream?: string
  campaignType: CampaignType
}> = ({ campaignId, campaignType, stream, substream }) => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showPlot, setShowPlot] = useState(false)

  const [interval, setTimeInterval] = useState<IntervalType>(TRAFFIC_BLOCKERS_INTERVALS.week)
  const [selectedStream, setSelectedStream] = useState<string | undefined>(stream)

  const { data: streamsData } = apiClient.stream.getStreamAutocomplete.useQuery(['streamsAutocomplete'], {
    query: {
      streamType: campaignType,
    },
  })

  const { data, refetch, isLoading } = apiClient.trafficBlockers.getTrafficBlockers.useQuery(
    ['trafficBlockers'],
    {
      query: {
        campaignId: +campaignId,
        stream: selectedStream,
        substream,
        interval,
      },
    },
    {
      enabled: false,
      queryKey: ['trafficBlockers'],
    }
  )

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (!isModalOpen) return
    refetch().catch(e =>
      showNotification({
        message: t('CampaignPage.Error'),
        description: e.message,
        type: 'error',
      })
    )
  }, [interval, isModalOpen, selectedStream])

  if (isModalOpen && (isLoading || !data)) return <Spin />

  const getData = (blockersInfo?: { total: number; items: Record<string, number> }): BlockersTableData[] => {
    let lastTotal = blockersInfo?.total || 0
    const data = Object.entries(blockersInfo?.items || [])
      .sort((a, b) => {
        return +b[1] - +a[1]
      })
      .map(([blockerName, count]) => {
        const t = {
          blockerName,
          count,
          percentage: lastTotal === 0 ? 0 : ((count / lastTotal) * 100).toFixed(2),
        }
        lastTotal -= count

        return t
      })

    return data
  }

  const percentageData = getData(data?.body)

  const columns: TableColumnsType<BlockersTableData> = [
    {
      title: 'Traffic Blockers',
      dataIndex: 'blockerName',
      key: 'title',
      render: (text: string) => <span>{t(`TrafficBlockersModal.BlockerName.${text}`)}</span>,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
      sortOrder: 'descend',
      render: text => (
        <Tooltip title="Amount of requests from total that not met campaign target">
          <span>{text}</span>
        </Tooltip>
      ),
    },
    // {
    //   title: 'Percentage',
    //   dataIndex: 'percentage',
    //   key: 'percentage',
    //   render: text => <span>{text}%</span>,
    // },
  ]
  const mapDataToHistogram = (data: BlockersTableData[]) => {
    const labels: string[] = []
    const datasets: { label: string; data: [number]; backgroundColor: string }[] = []
    const counts: number[] = []

    data.forEach(item => {
      labels.push(t(`TrafficBlockersModal.BlockerName.${item.blockerName}`))
      counts.push(item.count)

      // Generating random color for each dataset
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, 0.6)`

      datasets.push({
        label: t(`TrafficBlockersModal.BlockerName.${item.blockerName}`),
        data: [item.count],
        backgroundColor: color,
      })
    })

    return { labels, datasets, counts }
  }
  const { labels, counts } = mapDataToHistogram(percentageData)
  return (
    <div>
      <Tooltip title={t('TrafficBlockersModal.TrafficBlockers')}>
        <Button onClick={showModal} icon={<StopOutlined />}></Button>
      </Tooltip>
      <Modal
        width={'1000px'}
        title={
          <>
            <span>Campaign Traffic Blockers</span>
            <Tooltip title="Table shows total amount of requests that campaign might have on all streams. In table you can see amount of requests blocked by some target. For example, if you see Total: 50 and Language: 50 - that means, that all requests blocked by Language target on your campaign. Also you can select one stream to see information only for this stream.">
              <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
            </Tooltip>
          </>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col span={8}>
          <Row>
            <Radio.Group value={interval}>
              <Radio.Button
                value={TRAFFIC_BLOCKERS_INTERVALS.hour}
                onClick={() => setTimeInterval(TRAFFIC_BLOCKERS_INTERVALS.hour)}
              >
                {t('TrafficBlockersModal.TimeInterval.Hour')}
              </Radio.Button>
              <Radio.Button
                value={TRAFFIC_BLOCKERS_INTERVALS.day}
                onClick={() => setTimeInterval(TRAFFIC_BLOCKERS_INTERVALS.day)}
              >
                {t('TrafficBlockersModal.TimeInterval.Day')}
              </Radio.Button>
              <Radio.Button
                value={TRAFFIC_BLOCKERS_INTERVALS.week}
                onClick={() => setTimeInterval(TRAFFIC_BLOCKERS_INTERVALS.week)}
              >
                {t('TrafficBlockersModal.TimeInterval.Week')}
              </Radio.Button>
              <Radio.Button
                value={TRAFFIC_BLOCKERS_INTERVALS.month}
                onClick={() => setTimeInterval(TRAFFIC_BLOCKERS_INTERVALS.month)}
              >
                {t('TrafficBlockersModal.TimeInterval.Month')}
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row>
            <Select
              showSearch
              style={{ width: '100%' }}
              defaultActiveFirstOption={false}
              onChange={(value: string) => {
                value === null ? setSelectedStream(undefined) : setSelectedStream(value)
              }}
              placeholder="Select stream"
              value={selectedStream}
            >
              <Select.Option value={undefined}>-</Select.Option>
              {streamsData?.body.items.map(stream => (
                <Select.Option key={stream.uid} value={stream.uid}>
                  {stream.title}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Col>
        <Divider />
        <Col>
          <Button onClick={() => setShowPlot(!showPlot)}>{showPlot ? 'Hide' : 'Show'} histogram</Button>
          {showPlot ? <HistChart counts={counts} labels={labels} /> : <></>}
        </Col>
        <Typography.Title level={5}>Total requests: {data?.body.total}</Typography.Title>
        <Table rowKey="blockerName" size="small" columns={columns} dataSource={percentageData} pagination={false} />
      </Modal>
    </div>
  )
}
