import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { CreativeModerationStatusEnum } from './common/creative-moderation-status';
import { ErrorCode } from './common/error.enum';
const c = initContract();

export const rawCreativeSchema = z.object({
  filename: z.string(),
  mimetype: z.string(),
  userId: z.number(),
  campaignId: z.number(),
});

export const creativeSchema = z.object({
  id: z.number(),
  campaignId: z.number(),
  userId: z.number(),
  title: z.string().nullable(),
  body: z.string().nullable().optional(),
  tags: z.string().array().nullable(),
  status: z.enum(['WORKING', 'PAUSED']).nullable(),
  previewImgUrl: z.string().nullable(),
  previewImgPath: z.string().nullable(),
  previewImgRaw: rawCreativeSchema.nullable(),
  bodyImgUrl: z.string().nullable(),
  bodyImgPath: z.string().nullable(),
  moderationStatus: CreativeModerationStatusEnum.nullable(),
  syncedWithAerospikeAt: z.any().nullable(),
  bodyImgRaw: rawCreativeSchema.nullable(),
  createdAt: z.any().nullable(),
  updatedAt: z.any().nullable(),
  videoFileId: z.string().nullable(),
  videoFile: z
    .object({
      id: z.string().nullable(),
      key: z.string(),
      downloadUrl: z.string(),
    })
    .optional(),
});

export const PUSH_CREATIVE_PREVIEW_IMG_TYPE = 'push-preview-img';
export const PUSH_CREATIVE_BODY_IMG_TYPE = 'push-body-img';

export const creative = c.router({
  getCreativeListPaginated: {
    method: 'GET',
    path: '/creative',
    query: z.object({
      filter: z
        .object({
          fts: z.string().optional(),
          campaignIds: z.coerce.number().array().optional(),
          tags: z.string().array().optional(),
          moderationStatus: CreativeModerationStatusEnum.array().optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
    responses: {
      200: z.object({
        items: z.array(creativeSchema),
        filterValues: z.object({
          tags: z.array(z.string()),
          campaignIds: z.array(z.number()),
        }),
        pagination: paginationResponse,
      }),
    },
  },

  getCreativeForModerationListPaginated: {
    method: 'GET',
    path: '/creative.getCreativeForModerationListPaginated',
    query: z.object({
      pagination: paginationRequest,
    }),
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            title: z.string().nullable(),
            campaignId: z.number(),
            previewImgUrl: z.string().nullable(),
            bodyImgUrl: z.string().nullable(),
            videoFileUrl: z.string().nullable(),
            streamIds: z.string().array(),
          })
          .array(),
        pagination: paginationResponse,
      }),
    },
  },
  approveCreative: {
    method: 'POST',
    path: '/creative.approveCreative',
    body: z.object({
      creativeId: z.coerce.number(),
    }),
    responses: {
      200: z.object({}),
    },
  },
  declineCreative: {
    method: 'POST',
    path: '/creative.declineCreative',
    body: z.object({
      creativeId: z.coerce.number(),
    }),
    responses: {
      200: z.object({}),
    },
  },
  getCreativesTags: {
    method: 'GET',
    path: '/creative/tags',
    responses: {
      200: z.object({ tags: z.array(z.string()) }),
    },
  },
  createCreative: {
    method: 'POST',
    path: '/creative',
    contentType: 'multipart/form-data',
    body: c.type<{ data: FormData }>(),
    responses: {
      201: z.object({
        creativeId: z.number(),
      }),
    },
  },
  bulkCreateCreative: {
    method: 'POST',
    path: '/creative/bulkCreate',
    contentType: 'multipart/form-data',
    body: c.type<{ data: FormData }>(),
    responses: {
      201: z.object({
        creativeIds: z.number().array(),
      }),
    },
  },
  addExistingCreative: {
    method: 'POST',
    path: '/creative/addExisting',
    body: z.object({
      creativeId: z.number(),
      campaignId: z.number(),
    }),
    responses: {
      201: z.object({
        creativeId: z.number(),
      }),
    },
  },
  updateCreative: {
    method: 'PATCH',
    path: '/creative',
    contentType: 'multipart/form-data',
    body: c.type<{ data: FormData }>(),
    responses: {
      201: z.object({
        creativeId: z.number(),
      }),
    },
  },
  deleteCreative: {
    method: 'DELETE',
    path: '/creative',
    responses: {
      200: z.undefined(),
    },
    body: z.object({
      id: z.coerce.number(),
    }),
  },
  deleteCreativeImage: {
    method: 'DELETE',
    path: '/creative/image',
    responses: {
      200: z.object({
        isSuccess: z.boolean(),
      }),
    },
    body: z.object({
      id: z.coerce.number(),
      imageType: z.enum([
        PUSH_CREATIVE_PREVIEW_IMG_TYPE,
        PUSH_CREATIVE_BODY_IMG_TYPE,
      ]),
    }),
  },
  changeCreativeStatus: {
    method: 'PATCH',
    path: '/creative/status',
    responses: {
      200: z.undefined(),
      400: z.object({
        errorCode: z.enum([ErrorCode.CREATIVE_NOT_APPROVED]),
        message: z.string().optional(),
      }),
    },
    body: z.object({
      id: z.coerce.number(),
      status: z.enum(['WORKING', 'PAUSED']),
    }),
  },
  bulkCopyCreatives: {
    method: 'POST',
    path: '/creative/bulkCopy',
    responses: {
      200: z.object({
        copiedCreativesIds: z.number().array(),
      }),
    },
    body: z.object({
      ids: z.array(z.number()),
      campaignId: z.number(),
    }),
  },
});
