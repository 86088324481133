export const FEATURE_FLAG_MANAGE_USERS = 'MANAGE_USERS'

export const featureFlagNames = {
  [FEATURE_FLAG_MANAGE_USERS]: 'Manage users',
}

export const safeFeatureFlagNames = []

export const getFeatureFlagName = flag => featureFlagNames[flag]

export const canUseFeature = (user, flag) => {
  if (user?.isAdmin) {
    return true
  }
  const userFeatureFlags = user?.featureFlags || []
  return userFeatureFlags.includes(flag)
}

export const canManageUsers = user => canUseFeature(user, FEATURE_FLAG_MANAGE_USERS)

export const isAdmin = user => {
  return user?.isAdmin
}

export const canManageCampaigns = user => {
  return (
    user?.isAdmin ||
    user?.isAdvertiser ||
    user?.roles?.includes('ADMIN') ||
    user?.roles?.includes('INTERNAL_ADVERTISER') ||
    user?.roles?.includes('EXTERNAL_ADVERTISER')
  )
}
export const canManageCreatives = user => {
  return (
    user?.isAdmin ||
    user?.isAdvertiser ||
    user?.roles?.includes('ADMIN') ||
    user?.roles?.includes('INTERNAL_ADVERTISER') ||
    user?.roles?.includes('EXTERNAL_ADVERTISER')
  )
}
export const canManageStreams = user => {
  return (
    user?.isAdmin ||
    user?.isPublisher ||
    user?.roles?.includes('ADMIN') ||
    user?.roles?.includes('PUBLISHER')
  )
}
