import { EditOutlined } from '@ant-design/icons'

import { Alert, Button, Col, Row, Spin, Typography } from 'antd'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/layout/PageHeader/PageHeader'
import { UserApiLogs } from './components/user-api-logs'
import { UserCard } from './components/user-card'
import React, { FC } from 'react'
import { useAppState } from '../../../context/AppContext'

export const UserInfoPage: FC<{ params: { id: string } }> = ({ params }) => {
  const { apiClient } = useAppState()
  const { isLoading, data, error } = apiClient.user.getUserById.useQuery(['user', params.id], {
    query: {
      userId: parseInt(params.id, 10),
    },
  })

  if (isLoading) {
    return <Spin size="large" />
  }

  if (data?.status !== 200) {
    console.error(error)
    return <Alert type="error" message={'Error'} />
  }

  if (error?.body) {
    console.error(error)
    return <Alert type="error" message={String(error)} />
  }

  const user = data.body

  return (
    <>
      <PageHeader
        breadcrumbs={[{ title: 'Users', link: '/admin/users' }, { title: user.name || user.email }]}
        title={user.name || user.email}
        extra={[
          <Link to={`/admin/users/${user.id}/edit`}>
            <Button key="1" type="primary" icon={<EditOutlined />}>
              Edit
            </Button>
          </Link>,
        ]}
      >
        <UserCard user={user} />
      </PageHeader>
      <Row style={{ marginTop: 40 }}>
        <Col span={24}>
          <Typography.Title level={4}>Api Logs</Typography.Title>
          <UserApiLogs user={user} />
        </Col>
      </Row>
    </>
  )
}
