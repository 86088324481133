import { useGetUserAuditoryQuery } from '@pushflow/server/src/graphql/new/userAuditories/getUserAuditory/operations'
import { Alert, Col, Row, Typography } from 'antd'
import Loading from '../../../../components/Loading'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { UserAuditoryForm } from '../../../../containers/userAuditories/UserAuditoryForm'
import { UserAuditoryActions } from '../../../../containers/userAuditories/UserAuditoryActions'

const UserAuditoryEditPage = ({ params }) => {
  const { loading, error, data, refetch } = useGetUserAuditoryQuery({
    id: params.id,
  })

  if (loading && !data) return <Loading />
  if (error) return <Alert type="error" message={error.message} />

  return (
    <div className="UserAuditoryEditPage">
      <PageListHeader title={`Users Auditory ${data.getUserAuditory.userAuditory.title}`} />
      <Row>
        <Col span={6}>
          <Typography.Title
            style={{
              fontSize: '20px',
            }}
          >{`Auditory ID: ${data.getUserAuditory.userAuditory.id}`}</Typography.Title>
        </Col>
        <Col span={6}>
          <Typography.Title
            style={{
              fontSize: '20px',
            }}
          >{`Users Count: ${data.getUserAuditory.usersCounter}`}</Typography.Title>
        </Col>
      </Row>
      <UserAuditoryActions
        auditoryId={params.id}
        onChange={() => {
          refetch()
        }}
      />
      <UserAuditoryForm userAuditory={data.getUserAuditory.userAuditory} />
    </div>
  )
}

export default UserAuditoryEditPage
