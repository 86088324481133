import { Button, Checkbox, Form, Input } from 'antd'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../../helpers/formLayouts'
import showNotification from '../../../../helpers/showNotification'
import { ErrorCode, UserRole } from '@pushflow/backend-http-contract'
import { useAppState } from '../../../../context/AppContext'

interface FormValues {
  isActive: boolean
  isEmailConfirmed: boolean
  isPublisher: boolean
  name?: string
  preferredContact?: string
  isInternalAdvertiser: boolean
  isExternalAdvertiser: boolean
  fee?: number
  password?: string
}

const percentageValidator = (_: any, value: number): Promise<void> => {
  if (value === undefined) {
    return Promise.resolve()
  }

  if (value < 0 || value > 100) {
    return Promise.reject(new Error('Please enter an integer between 0 and 100'))
  }

  return Promise.resolve()
}

export const UserEditForm: FC<{
  user: {
    roles: UserRole[]
    preferredContact: string | null
    email: string
    isActive: boolean
    id: number
    name: string | null
    fee: number | null
    isEmailConfirmed: boolean
    updatedAt: string | null
    postback: string | null
    createdAt: string
  }
}> = ({ user }) => {
  const navigate = useNavigate()
  const { apiClient } = useAppState()
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const [showFeeInput, setShowFeeInput] = useState<boolean>(user.roles.includes(UserRole.EXTERNAL_ADVERTISER))

  const onCheckboxChange = (e: any) => {
    setShowFeeInput(e.target.checked)
  }

  const initialValues: FormValues = {
    isActive: user.isActive,
    isEmailConfirmed: user.isEmailConfirmed,
    isPublisher: user.roles.includes(UserRole.PUBLISHER),
    name: user.name || '',
    fee: user.fee || undefined,
    preferredContact: user.preferredContact || '',
    isInternalAdvertiser: user.roles.includes(UserRole.INTERNAL_ADVERTISER),
    isExternalAdvertiser: user.roles.includes(UserRole.EXTERNAL_ADVERTISER),
  }

  const submit = async (values: FormValues) => {
    const roles: UserRole[] = []

    if (values.isInternalAdvertiser) {
      roles.push(UserRole.INTERNAL_ADVERTISER)
    }

    if (values.isExternalAdvertiser) {
      roles.push(UserRole.EXTERNAL_ADVERTISER)
    }

    if (values.isPublisher) {
      roles.push(UserRole.PUBLISHER)
    }

    const { status, body } = await apiClient.user.updateUser.mutation({
      body: {
        id: Number(user.id),
        roles,
        fee: values.fee,
        isActive: values.isActive,
        isEmailConfirmed: values.isEmailConfirmed,
        name: values.name,
        preferredContact: values.preferredContact,
      },
    })

    if (status === 200) {
      showNotification({ type: 'success', message: 'User successfully updated!' })
      navigate(`/admin/users/${body.id}`)
    } else if (status === 400) {
      if (body.errorCode === ErrorCode.USER_NOT_FOUND) {
        showNotification({
          type: 'warning',
          message: 'User not found',
        })
        setSubmitting(false)
      }
    }
  }

  return (
    <Form<FormValues>
      form={form}
      onFinish={submit}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      <Form.Item name="password" label="Password">
        <Input />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="preferredContact" label="Preferred contact">
        <Input />
      </Form.Item>
      <Form.Item label="Active" name="isActive" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Is email confirmed" name="isEmailConfirmed" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Publisher" name="isPublisher" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Inteam advertiser" name="isInternalAdvertiser" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="External advertiser" name="isExternalAdvertiser" valuePropName="checked">
        <Checkbox onChange={onCheckboxChange}>Enabled</Checkbox>
      </Form.Item>
      {showFeeInput && (
        <Form.Item
          name="fee"
          label="Fee"
          rules={[{ required: true, message: 'Please enter a percentage value' }, { validator: percentageValidator }]}
        >
          <Input type={'number'} required />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayoutOffset}>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}
