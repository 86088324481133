import { gql, useQuery } from '@apollo/client'

export const IS_USER_IN_AUDITORY_QUERY = gql`
  query IsUserInAuditory($input: IsUserInAuditoryInput!) {
    isUserInAuditory(input: $input) {
      isInAuditory
    }
  }
`

export const useIsUserInAuditoryQuery = (input, options = {}) => {
  return useQuery(IS_USER_IN_AUDITORY_QUERY, {
    variables: { input },
    ...options,
  })
}
