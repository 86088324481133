import { gql } from 'graphql-tag'

export const USER_AUDITORY_DATA = gql`
  fragment UserAuditoryData on UserAuditory {
    __typename
    id
    title

    # users

    userId
    createdAt
    updatedAt
  }
`
