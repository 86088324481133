import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { PAUSED, WORKING } from '../../../common/types/campaignStatusTypes'
import showNotification from '../../../helpers/showNotification'

import { Card, Dropdown, Tooltip } from 'antd'
import { EditOutlined, EllipsisOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Player } from 'video-react'
import { VideoCreative, VideoCreativeForm } from './video-creative-form'
import React from 'react'
import { useAppState } from '../../../context/AppContext'
import { ErrorCode } from '@pushflow/backend-http-contract'
import { CreativeModerationIndicator } from './creative-moderation-indicator'

export const VideoCreativeCard: FC<{
  creative: VideoCreative
  campaignId: number
  copyCreative: any
  defaultOpenedCreativeId: number | null
  setDefaultOpenedCreativeId: any
  refetch: any
}> = ({ creative, campaignId, copyCreative, defaultOpenedCreativeId, setDefaultOpenedCreativeId, refetch }) => {
  const { apiClient } = useAppState()
  const { id, status, videoFile, moderationStatus } = creative

  const { mutateAsync: deleteCreative } = apiClient.creative.deleteCreative.useMutation()
  const { mutateAsync: changeCreativeStatus } = apiClient.creative.changeCreativeStatus.useMutation()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (defaultOpenedCreativeId === +id) {
      setDefaultOpenedCreativeId(null)
      setVisible(true)
    }
  }, [defaultOpenedCreativeId])
  function openModal() {
    setVisible(true)
  }

  function remove() {
    deleteCreative({
      body: { id },
    }).then(() => refetch && refetch())
  }

  const copy = () => {
    copyCreative(creative)
  }

  function updateStatus() {
    changeCreativeStatus({
      body: {
        id,
        status: status === PAUSED ? WORKING : PAUSED,
      },
    })
      .then(() => {
        showNotification({
          type: 'success',
          message: status === PAUSED ? WORKING : PAUSED,
        })
        refetch()
      })
      .catch(({ body }) => {
        let errorMessage = 'Unknown error'
        if (body?.errorCode === ErrorCode.CREATIVE_NOT_APPROVED) {
          errorMessage = 'This creative not approved'
        }
        showNotification({
          type: 'error',
          message: errorMessage,
        })
      })
  }
  const actionButtons = [
    <div style={{ fontSize: 13, color: '#8e8e8e' }} key="1">
      ID: {id}
    </div>,
    <CreativeModerationIndicator moderationStatus={moderationStatus} />,
    status === PAUSED ? (
      <Tooltip title={t('Creative.Help.Start tooltip')} placement="bottom">
        <PlayCircleOutlined style={{ color: 'red' }} key="2" onClick={updateStatus} />
      </Tooltip>
    ) : (
      <Tooltip title={t('Creative.Help.Pause tooltip')} placement="bottom">
        <PauseCircleOutlined style={{ color: 'green' }} key="2" onClick={updateStatus} />
      </Tooltip>
    ),

    <EditOutlined key="3" onClick={openModal} />,
    <Dropdown
      key="6"
      menu={{
        items: [
          { label: t('Actions.Delete'), key: 'delete', onClick: () => remove() },
          { label: t('Actions.Copy'), key: 'copy', onClick: () => copy() },
        ],
      }}
      trigger={['click']}
    >
      <EllipsisOutlined />
    </Dropdown>,
  ]

  return (
    <>
      <Card
        hoverable
        style={{ minWidth: '300px' }}
        bodyStyle={{ padding: '0px' }}
        headStyle={{ paddingLeft: '5px', paddingRight: '5px' }}
        cover={<Player playsInline width={300} autoPlay={true} muted={true} src={videoFile?.downloadUrl} />}
        actions={actionButtons}
      ></Card>

      <VideoCreativeForm
        creative={creative}
        campaignId={campaignId}
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
      />
    </>
  )
}
