import { useCreateOrUpdateUserAuditoryMutation } from '@pushflow/server/src/graphql/new/userAuditories/createOrUpdateUserAuditory/operations'
import * as Sentry from '@sentry/react'
import { Alert, Button, Form } from 'antd'
import pick from 'lodash/pick'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultFormLayout } from '../../helpers/formLayouts'
import showNotification from '../../helpers/showNotification'
import Input from '../../components/forms/Input/Input'

export const UserAuditoryForm = ({ userAuditory = {} }) => {
  const navigate = useNavigate()
  const { mutate: createOrUpdateUserAuditory } = useCreateOrUpdateUserAuditoryMutation()
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const validateForm = () => {
    setIsFormValid(
      form.getFieldsError().every(item => {
        return item.errors.length === 0
      })
    )
    setIsFormTouched(true)
  }

  const neededUserAuditoryFeilds = pick(userAuditory, ['title'])
  const initialValues = {
    ...neededUserAuditoryFeilds,
  }

  const submit = values => {
    createOrUpdateUserAuditory({
      variables: {
        input: {
          id: userAuditory.id,
          ...values,
        },
      },
    })
      .then(result => {
        if (!result.errors) {
          showNotification({
            type: 'success',
            message: userAuditory.id ? 'List succesfully updated!' : 'List succesfully created!',
          })
          navigate(`/userauditory/${result.data.createOrUpdateUserAuditory.userAuditory.id}`)
        }
      })
      .catch(err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
        setSubmitting(false)
      })
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      onFinishFailed={validateForm}
      onFieldsChange={validateForm}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      <Input isRequired name="title" label="Title" {...defaultFormLayout} />

      {!isFormValid && isFormTouched && (
        <Form.Item {...defaultFormLayout}>
          <Alert message="Some fields are invalid" type="error" />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayout}>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
