import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { USER_AUDITORY_DATA } from '../UserAuditory/operations.js'

export const DELETE_USER_AUDITORY_MUTATION = gql`
  mutation DeleteUserAuditory($input: DeleteUserAuditoryInput!) {
    deleteUserAuditory(input: $input) {
      userAuditory {
        ...UserAuditoryData
      }
    }
  }
  ${USER_AUDITORY_DATA}
`

export const useDeleteUserAuditoryMutation = (options = {}) => {
  const [mutate, { data, error }] = useMutation(DELETE_USER_AUDITORY_MUTATION, {
    ...options,
  })

  return { mutate, data, error }
}
