import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { UserAuditoryForm } from '../../../../containers/userAuditories/UserAuditoryForm'

const UserAuditoryCreatePage = () => {
  return (
    <div className="UserAuditoryCreatePage">
      <PageListHeader title="New User Auditory" />
      <UserAuditoryForm />
    </div>
  )
}

export default UserAuditoryCreatePage
