import { Alert, Button, Checkbox, Form, Radio, Select, Typography } from 'antd'
import pick from 'lodash/pick'
import { useEffect, useMemo, useState } from 'react'

import React from 'react'

import config from '../../../config'
import StreamFAQAccordion from '../../streams/StreamFAQAccordion'
import { CUSTOM, RTB, XML, JSON } from '../../../common/types/streamFormats'
import { IMPRESSION, NOTIFICATION, REDIRECT } from '../../../common/types/streamBillingTypes'
import { LINK, PUSH, VIDEO_PRE_ROLL } from '../../../common/types/campaignTypes'
import { CPC, CPM } from '../../../common/types/campaignBidModels'
import { useDspDomains, useFetchPublishers } from '../../streams/hooks'
import { defaultFormLayout } from '../../../helpers/formLayouts'
import Input from '../../../components/forms/Input/Input'
import { TextAreaWithMacros } from './text-area-with-macros'
import { AdmType } from '@pushflow/backend-http-contract/dist/common/adm-types'

const { Text, Title } = Typography

export interface IStream {
  id: number
  uid: string
  title: string
  format: typeof XML | typeof JSON | typeof RTB | typeof CUSTOM
  region: 'eu' | 'us'
  responseTemplate?: string
  streamType: typeof PUSH | typeof LINK | typeof VIDEO_PRE_ROLL
  billing: typeof REDIRECT | typeof NOTIFICATION | typeof IMPRESSION
  bidModel: typeof CPC | typeof CPM
  encrypted: boolean
  hidden: boolean
  timeout: number
  hasClientHints: boolean
  userId: number
  admType?: AdmType
}

export const StreamForm: React.FC<{
  stream?: IStream
  onSubmit?: (val?: any) => void
  user: { id: number | string; isAdmin: boolean }
}> = ({ stream = {} as Partial<IStream>, onSubmit, user }) => {
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const streamType = Form.useWatch<typeof PUSH | typeof LINK | typeof VIDEO_PRE_ROLL>('streamType', form)
  const streamFormat = Form.useWatch('format', form)
  const streamEncrypted = Form.useWatch('encrypted', form)
  const streamRegion = Form.useWatch('region', form)

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const validateForm = () => {
    setIsFormValid(
      form.getFieldsError().every(item => {
        return item.errors.length === 0
      })
    )
    setIsFormTouched(true)
  }

  const initialValues = useMemo(() => {
    const neededStreamFields: any = pick(stream, [
      'title',
      'format',
      'region',
      'responseTemplate',
      'streamType',
      'billing',
      'bidModel',
      'encrypted',
      'hidden',
      'timeout',
      'hasClientHints',
      'userId',
      'admType',
    ])

    return {
      streamType: PUSH,
      region: 'eu',
      admType: null,
      timeout: 300,
      hasClientHints: false,
      bidModel: CPC,
      encrypted: false,
      hidden: false,
      format: XML,
      responseTemplate: '',
      billing: REDIRECT,
      userId: neededStreamFields.userId,
      ...neededStreamFields,
    }
  }, [stream, user])

  const { data: usersAutoComplete } = useFetchPublishers()

  const submit = (values: any) => {
    setSubmitting(true)
    onSubmit && onSubmit({ id: stream.id, ...values })
    setSubmitting(false)
  }

  const renderUrl = (streamData: any, form: any) => {
    let basePath = 'unknown'

    if (streamType === PUSH) {
      basePath = '/dsp/push/get'
    } else if (streamType === LINK) {
      basePath = '/dsp/link/get'
    } else if (streamType === VIDEO_PRE_ROLL) {
      basePath = '/dsp/video/get'
    }

    const protocol = streamEncrypted ? 'https' : 'http'
    const selectedRegion = Form.useWatch('region', form)
    const trafficTypesMap = {
      [PUSH]: 'SSP_PUSH',
      [LINK]: 'SSP_LINK',
      [VIDEO_PRE_ROLL]: 'SSP_VIDEO',
    }
    const trafficType = trafficTypesMap[streamType]

    const { data: domains, refetch } = useDspDomains(streamRegion || 'eu', trafficType || 'SSP_PUSH')
    useEffect(() => {
      refetch()
    }, [selectedRegion])
    const url = domains?.[0]?.address || config.dspUrl
    const link = streamFormat
      ? `${protocol}://${url}${basePath}?ssp=${streamData.uid}&type=${streamFormat.toLowerCase()}`
      : ''
    return (
      <>
        <Title level={2}>URL</Title>
        <Text code copyable>
          {link}
        </Text>
      </>
    )
  }

  const handleStreamTypeChange = (e: any) => {
    if (e.target.value === PUSH) {
      form.setFields([{ name: 'bidModel', value: CPC }])
    } else if (e.target.value === LINK) {
      form.setFields([{ name: 'bidModel', value: CPM }])
    } else if (e.target.value === VIDEO_PRE_ROLL) {
      form.setFields([
        { name: 'bidModel', value: CPC },
        { name: 'format', value: RTB },
        { name: 'admType', value: AdmType.IN_STREAM_ADS_VAST_TAG },
      ])
    }
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      onFinishFailed={validateForm}
      onFieldsChange={validateForm}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      {user && user.isAdmin ? (
        <Form.Item {...defaultFormLayout} label="Owner" name="userId">
          <Select
            options={usersAutoComplete?.map((u: { id: number; email: string }) => ({
              value: +u.id,
              label: +user.id === +u.id ? 'Me' : `ID: ${u.id} | ${u.email}`,
            }))}
          />
        </Form.Item>
      ) : (
        <></>
      )}

      <Input
        // @ts-ignore
        isRequired
        name="title"
        label="Title"
        {...defaultFormLayout}
      />

      <Form.Item {...defaultFormLayout} label="Type" name="streamType">
        <Radio.Group name="streamType" onChange={handleStreamTypeChange}>
          <Radio.Button
            disabled={
              // @ts-ignore
              !!stream.id && streamType !== PUSH
            }
            value={PUSH}
          >
            Push
          </Radio.Button>
          <Radio.Button
            disabled={
              // @ts-ignore
              !!stream.id && streamType !== LINK
            }
            value={LINK}
          >
            Link
          </Radio.Button>
          <Radio.Button
            disabled={
              // @ts-ignore
              !!stream.id && streamType !== VIDEO_PRE_ROLL
            }
            value={VIDEO_PRE_ROLL}
          >
            Video
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item {...defaultFormLayout} label="Region" name="region">
        <Select
          options={[
            { value: 'us', label: 'US (East Cost)' },
            { value: 'eu', label: 'EU (Germany)' },
          ]}
        />
      </Form.Item>

      <Form.Item {...defaultFormLayout} label="Integration type" name="format">
        <Radio.Group name="format">
          <Radio.Button disabled={streamType === VIDEO_PRE_ROLL} value={XML}>
            {XML}
          </Radio.Button>
          <Radio.Button disabled={streamType === VIDEO_PRE_ROLL} value={JSON}>
            {JSON}
          </Radio.Button>
          <Radio.Button value={RTB}>{RTB}</Radio.Button>
          <Radio.Button disabled={streamType === VIDEO_PRE_ROLL} value={CUSTOM}>
            {CUSTOM}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      {streamFormat === RTB && streamType === VIDEO_PRE_ROLL ? (
        <Form.Item {...defaultFormLayout} label="Adm type" name="admType">
          <Radio.Group name="admType">
            <Radio.Button defaultChecked={true} value={AdmType.IN_STREAM_ADS_VAST_TAG}>
              VAST_TAG
            </Radio.Button>
            <Radio.Button value={AdmType.IN_STREAM_ADS_VAST_LOCAL}>VAST_LOCAL</Radio.Button>
          </Radio.Group>
        </Form.Item>
      ) : null}

      {streamFormat === CUSTOM ? (
        <Form.Item {...defaultFormLayout} label="Custom format">
          <TextAreaWithMacros
            name="responseTemplate"
            form={form}
            macroses={
              streamType === PUSH
                ? [
                    'REQUEST_ID',
                    'CREATIVE_TITLE',
                    'CLICK_URL',
                    'CREATIVE_BODY',
                    'BID',
                    'NURL_URL',
                    'IMAGE_URL',
                    'ICON_URL',
                  ]
                : ['REQUEST_ID', 'CLICK_URL', 'BID', 'NURL_URL']
            }
          />
        </Form.Item>
      ) : null}

      <Form.Item {...defaultFormLayout} label="Billing type" name="billing">
        <Radio.Group name="billing">
          <Radio.Button value={REDIRECT}>Redirect</Radio.Button>
          <Radio.Button value={NOTIFICATION} disabled={[PUSH].includes(streamType)}>
            Notification
          </Radio.Button>
          <Radio.Button value={IMPRESSION} disabled={[PUSH, VIDEO_PRE_ROLL].includes(streamType)}>
            Impression
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Input
        // @ts-ignore
        isRequired
        name="timeout"
        label="Timeout"
        isNumber
        min={0}
        {...defaultFormLayout}
      />

      <Form.Item {...defaultFormLayout} label="Bid model" name="bidModel">
        <Radio.Group>
          <Radio.Button value={CPC}>{CPC}</Radio.Button>
          <Radio.Button value={CPM} disabled={[PUSH].includes(streamType)}>
            {CPM}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        {...defaultFormLayout}
        label="Encryption"
        name="encrypted"
        valuePropName="checked"
        tooltip="Enable or disable SSL(https) for auction URL"
      >
        <Checkbox>Enabled</Checkbox>
      </Form.Item>

      {user && user.isAdmin && (
        <Form.Item
          {...defaultFormLayout}
          label="Has Client Hints"
          name="hasClientHints"
          valuePropName="checked"
          tooltip="Check as true if stream sends client hints"
        >
          <Checkbox>Enabled</Checkbox>
        </Form.Item>
      )}
      {user && user.isAdmin && (
        <Form.Item
          {...defaultFormLayout}
          label="Hide from reports"
          name="hidden"
          valuePropName="checked"
          tooltip="Hide from reports"
        >
          <Checkbox>Enabled</Checkbox>
        </Form.Item>
      )}

      {!isFormValid && isFormTouched && (
        <Form.Item {...defaultFormLayout}>
          <Alert message="Some fields are invalid" type="error" />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayout}>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
          Save
        </Button>
      </Form.Item>

      {stream.uid && renderUrl(stream, form)}

      <Title level={2}>FAQ</Title>
      <StreamFAQAccordion />
    </Form>
  )
}
