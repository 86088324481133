import { PlusOutlined } from '@ant-design/icons'
import PageListHeader from '../../../components/layout/PageListHeader/PageListHeader'

import React, { FC, useEffect, useState } from 'react'
import { UserList } from './components/user-list'
import { Spin, Tabs } from 'antd'
import { useAppState } from '../../../context/AppContext'
import { UserStatus } from '@pushflow/backend-http-contract'

const NEW_TAB = 'NEW_TAB'
const ACTIVE_TAB = 'ACTIVE_TAB'
const INACTIVE_TAB = 'INACTIVE_TAB'

const statuses: Record<string, UserStatus> = {
  [NEW_TAB]: UserStatus.NEW,
  [ACTIVE_TAB]: UserStatus.ACTIVE,
  [INACTIVE_TAB]: UserStatus.INACTIVE,
}

export const UserListPage: FC = () => {
  const { apiClient } = useAppState()
  const { data: hasNewUsersData, isLoading } = apiClient.user.hasNewUsers.useQuery(['hasNewUsers'])

  const [currentTab, setCurrentTab] = useState<keyof typeof statuses>(
    hasNewUsersData?.body.hasNewUsers ? NEW_TAB : ACTIVE_TAB
  )

  useEffect(() => {
    if (hasNewUsersData?.body.hasNewUsers) {
      setCurrentTab(NEW_TAB)
    }
  }, [hasNewUsersData])

  if (isLoading) {
    return <Spin />
  }
  const status = statuses[currentTab]
  return (
    <div className="UserListPage">
      <PageListHeader
        title="Users"
        buttons={[
          {
            link: `/admin/users/new`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: 'New User',
          },
        ]}
      />

      <Tabs
        activeKey={currentTab}
        onChange={key => setCurrentTab(key as keyof typeof statuses)}
        items={[
          { key: NEW_TAB, label: 'New users', children: <UserList status={status} /> },
          { key: ACTIVE_TAB, label: 'Active users', children: <UserList status={status} /> },
          { key: INACTIVE_TAB, label: 'Inactive users', children: <UserList status={status} /> },
        ]}
      />
    </div>
  )
}
