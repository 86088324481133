import { PlusOutlined } from '@ant-design/icons'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import UserAuditoriesList from './UserAuditoriesList'
import { useTranslation } from 'react-i18next'

const UserAuditoriesListPage = () => {
  const { t } = useTranslation()
  return (
    <div className="UserAuditoriesListPage">
      <PageListHeader
        title="UserAuditories"
        buttons={[
          {
            link: `/userauditory/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          },
        ]}
      />
      <UserAuditoriesList />
    </div>
  )
}

export default UserAuditoriesListPage
