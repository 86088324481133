import {
  CopyOutlined,
  DeleteTwoTone,
  EditTwoTone,
  PauseCircleOutlined,
  PlayCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import * as Sentry from '@sentry/react'

import {
  Alert,
  Input as AntdInput,
  Button,
  Checkbox,
  Form,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tag,
} from 'antd'
import { FC, lazy, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteButton from '../../components/buttons/DeleteButton/DeleteButton'
import PageHeader from '../../components/layout/PageHeader/PageHeader'
import CreativeForm from '../../containers/campaign/CreativeForm'
import componentVariationsFabric from '../../helpers/components-fabric.helper'
import showNotification from '../../helpers/showNotification'
import { Link } from 'react-router-dom'
import { FixedTableFooter } from '../../components/FixedTableFooter/FixedTableFooter'
import { useAppState } from '../../context/AppContext'

import Search from 'antd/lib/input/Search'
import { PAUSED, WORKING } from '../../common/types/campaignStatusTypes'
import React from 'react'
import { VideoCreative, VideoCreativeForm } from '../campaign-settings/components/video-creative-form'
import { CreativeModerationStatus } from '@pushflow/backend-http-contract/dist/common/creative-moderation-status'
import { ErrorCode } from '@pushflow/backend-http-contract'

export interface ICreative {
  id: number
  campaignId: number
  title: string | null
  body?: string | null
  tags: string[] | null
  previewImgUrl: string | null
  previewImgPath: string | null
  previewImgRaw: any
  bodyImgUrl: string | null
  bodyImgPath: string | null
  bodyImgRaw: any
  userId: number
  status: 'WORKING' | 'PAUSED' | null
  videoFileId: string | null
  moderationStatus: CreativeModerationStatus
}

const DeleteCreativeIcon: FC<{ id: number; refetch: () => void }> = ({ id, refetch }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: deleteCreative } = apiClient.creative.deleteCreative.useMutation({
    onSettled: () => {
      refetch()
    },
  })
  return (
    <DeleteButton
      key="3"
      isOnlyIcon
      onClick={() => {
        deleteCreative({
          body: { id },
        })
      }}
    >
      <DeleteTwoTone />
    </DeleteButton>
  )
}

const ChangeCreativeStatus: FC<{ creative: any; refetch: () => void }> = ({ creative, refetch }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: changeCreativeStatus } = apiClient.creative.changeCreativeStatus.useMutation({
    onSettled: () => {
      refetch()
    },
  })

  const changeStatus = async (id: number) => {
    await changeCreativeStatus({
      body: {
        id,
        status: creative.status === PAUSED ? WORKING : PAUSED,
      },
    }).catch(({ body }) => {
      let errorMessage = 'Unknown error'
      if (body?.errorCode === ErrorCode.CREATIVE_NOT_APPROVED) {
        errorMessage = 'This creative not approved'
      }
      showNotification({
        type: 'error',
        message: errorMessage,
      })
    })
  }

  if (creative.status === PAUSED) {
    return <PlayCircleOutlined onClick={() => changeStatus(creative.id)} />
  } else {
    return <PauseCircleOutlined onClick={() => changeStatus(creative.id)} />
  }
}

const EditCreativeIcon: FC<{ creative: ICreative | VideoCreative; refetch: () => void }> = ({ creative, refetch }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <EditTwoTone
        onClick={() => {
          setVisible(true)
        }}
      />
      {'videoFile' in creative && creative.videoFile !== null ? (
        <VideoCreativeForm
          refetch={refetch}
          campaignId={creative.campaignId}
          visible={visible}
          setVisible={setVisible}
          creative={creative}
        />
      ) : (
        <CreativeForm
          refetch={refetch}
          campaignId={creative.campaignId}
          visible={visible}
          setVisible={setVisible}
          creative={creative}
        />
      )}
    </>
  )
}

const BulkCopyCreatives: FC<{
  allSelectedCreatives: any[]
  refetch: any
  campaignsData: { id: number; title: string }[]
}> = ({ allSelectedCreatives, refetch, campaignsData }) => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const [form] = Form.useForm()
  const url = Form.useWatch('url', form)
  const campaignId = Form.useWatch('campaignId', form)
  const [isFormValid, setIsFormValid] = useState(false)
  const validateForm = () =>
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true)
        return true
      })
      .catch(() => {
        setIsFormValid(false)
        return false
      })
  useEffect(() => {
    setIsFormValid(true)
  }, [url])
  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])

  const { mutateAsync: bulkCopyCreatives } = apiClient.creative.bulkCopyCreatives.useMutation()
  const handle = () => {
    validateForm().then(isValid => {
      if (!isValid) {
        return
      }
      setLoading(true)
      bulkCopyCreatives({
        body: {
          ids: allSelectedCreatives.map(c => c.id),
          campaignId: +campaignId,
        },
      })
        .then(result => {
          showNotification({
            type: 'success',
            message: `${t('CreativesListPage.Bulk Copy Success')} (${result.body.copiedCreativesIds.length})`,
          })
        })
        .catch((err: any) => {
          showNotification({ type: 'error', message: err.message })
          if (process.env.NODE_ENV !== 'development') {
            Sentry.captureException(err)
          }
        })
        .finally(() => {
          setLoading(false)
          setOpen(false)
          refetch()
        })
    })
  }
  return (
    <>
      <Button
        disabled={!allSelectedCreatives.length || loading}
        loading={loading}
        icon={<CopyOutlined />}
        onClick={() => setOpen(true)}
      >
        {t('CreativesListPage.Bulk Copy Button')}
      </Button>

      <Modal
        open={open}
        title={t('CreativesListPage.Bulk Copy Title')}
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            {t('CreativesListPage.Bulk Copy Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={!allSelectedCreatives.length || loading || !isFormValid}
            onClick={() => handle()}
          >
            {t('CreativesListPage.Bulk Copy Confirm')}
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: t('CreativesListPage.Table.Title'),
              dataIndex: 'title',
              key: 'title',
            },
          ]}
          dataSource={allSelectedCreatives}
          rowKey="id"
          pagination={false}
          footer={() => (
            <Form form={form} key="form" layout="vertical" initialValues={{ url: '', campaignId: '' }}>
              <Form.Item name={'campaignId'} label="Copy To Campaign With ID" rules={[{ required: true }]}>
                <Select
                  options={campaignsData.map(c => ({
                    value: c.id,
                    label: `${c.id} | ${c.title}`,
                  }))}
                />
              </Form.Item>
            </Form>
          )}
        />
      </Modal>
    </>
  )
}

const CreativesList: FC<{ fts: string | undefined; onDataLoaded: any }> = ({ fts, onDataLoaded }) => {
  const { user, apiClient } = useAppState()
  const [pageNumber, setPageNumber] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [filterByCampaignIds, setFilterByCampaignIds] = useState([])
  const [filterByTags, setFilterByTags] = useState([])

  const [selectedCreativesIds, setSelectedCreativesIds] = useState<number[]>([])

  const creativesQuery = apiClient.creative.getCreativeListPaginated.useQuery(
    [fts, pageNumber, pageSize, filterByCampaignIds.join(), filterByTags.join()],
    {
      query: {
        filter: { campaignIds: filterByCampaignIds, tags: filterByTags, fts: fts },
        pagination: { pageNumber, pageSize },
      },
    }
  )
  const campaignsQuery = apiClient.campaign.getCampaignListPaginated.useQuery(['campaigns', 0, 10000], {
    query: {
      pagination: { pageNumber: 0, pageSize: 10000 },
      filter: {
        campaignTypes: ['PUSH'],
      },
    },
  })
  const { t } = useTranslation()

  const searchInputEl = useRef<any>(null)
  const selectInputEl = useRef(null)

  useEffect(() => {
    creativesQuery.refetch()
  }, [pageNumber, pageSize])

  const campaignsData = campaignsQuery.data?.body.items.map((c: any) => ({ id: c.id, title: c.title })) || []
  const creativesTags = creativesQuery.data?.body?.filterValues?.tags || []
  const creatives = creativesQuery.data?.body.items || []
  const totalCount = creativesQuery.data?.body.pagination.total || 0

  const allSelectedCreatives = creatives.filter(creative => selectedCreativesIds.includes(creative.id))

  useEffect(() => {
    creativesQuery.refetch()
  }, [pageNumber, pageSize, filterByCampaignIds, filterByTags])

  useEffect(() => {
    if (creativesQuery.isSuccess) {
      onDataLoaded()
    }
  }, [creativesQuery.data])

  if (creativesQuery.isError) {
    return <Alert type="error" message={creativesQuery.error.body as any} />
  }

  const getColumnSearchProps = (
    setValue: any,
    p: { type: 'text' | 'boolean' | 'multiselect'; placeholder: string; options: any }
  ) => {
    const { type, placeholder, options } = p
    return {
      filterDropdown: (params: { setSelectedKeys: any; selectedKeys: any; confirm: any; clearFilters?: any }) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = params
        if (type === 'text') {
          return (
            <div style={{ padding: 8 }}>
              <AntdInput
                ref={searchInputEl}
                placeholder={placeholder}
                value={selectedKeys[0]}
                onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
                onPressEnter={() => {
                  confirm()
                  setValue(selectedKeys[0])
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                    setValue(selectedKeys[0])
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters?.()
                    confirm()
                    setValue('')
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    })
                    setValue(selectedKeys[0])
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          )
        }
        if (type === 'boolean') {
          return (
            <div style={{ padding: 8 }}>
              <div style={{ marginBottom: 8 }}>
                <Radio.Group
                  onChange={({ target }) => {
                    setSelectedKeys([target.value])
                  }}
                  value={selectedKeys[0]}
                >
                  <Radio value={true}>true</Radio>
                  <Radio value={false}>false</Radio>
                </Radio.Group>
              </div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                    setValue(selectedKeys[0])
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters?.()
                    confirm()
                    setValue(null)
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    })
                    setValue(selectedKeys[0])
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          )
        }

        if (type === 'multiselect') {
          return (
            <div style={{ padding: 8 }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                    setValue(selectedKeys)
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters?.()
                    confirm()
                    setValue([])
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    })
                    setValue(selectedKeys)
                  }}
                >
                  Filter
                </Button>
              </Space>
              <Select
                ref={selectInputEl}
                mode="multiple"
                allowClear
                style={{ marginTop: 8, display: 'block', maxWidth: 500 }}
                placeholder={placeholder}
                value={selectedKeys}
                onChange={ids => setSelectedKeys(ids)}
                options={options}
              />
            </div>
          )
        }

        return <>Wrong filter type</>
      },
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInputEl.current?.select(), 100)
        }
      },
    }
  }

  let columns: TableColumnsType<ICreative | VideoCreative> = [
    {
      title: () => {
        return (
          <Checkbox
            indeterminate={selectedCreativesIds.length > 0 && selectedCreativesIds.length < totalCount}
            checked={selectedCreativesIds.length === totalCount}
            onChange={({ target }) => {
              if (target.checked) {
                setSelectedCreativesIds(creatives.map(c => c.id))
              } else {
                setSelectedCreativesIds([])
              }
            }}
          />
        )
      },
      render: (_: any, creative: any) => {
        return (
          <Checkbox
            checked={selectedCreativesIds.includes(creative.id)}
            onChange={() => {
              if (selectedCreativesIds.includes(creative.id)) {
                setSelectedCreativesIds(selectedCreativesIds.filter(id => id !== creative.id))
              } else {
                setSelectedCreativesIds([...selectedCreativesIds, creative.id])
              }
            }}
          />
        )
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: t('CreativesListPage.Table.Campaign ID'),
      dataIndex: 'campaignId',
      ...getColumnSearchProps(setFilterByCampaignIds, {
        type: 'multiselect',
        options: campaignsData.map(c => ({
          label: c.id,
          value: c.id,
        })),
        placeholder: 'Campaign ID',
      }),
      render: campaignId => (
        <Link to={`/campaign/${campaignId}`}>
          <b>{campaignId}</b>
        </Link>
      ),
    },
    {
      title: t('CreativesListPage.Table.Title'),
      dataIndex: 'title',
      render: title => <>{title || '❌'}</>,
    },
    {
      title: t('CreativesListPage.Table.Body'),
      dataIndex: 'body',
      render: body => <>{body || '❌'}</>,
    },
    {
      title: t('CreativesListPage.Table.Tags'),
      dataIndex: 'tags',
      render: tags => (
        <>
          {tags && Array.isArray(tags) && tags.length > 0 ? (
            <Space size={[0, 8]} wrap>
              {tags.map((tag: string) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Space>
          ) : (
            '❌'
          )}
        </>
      ),
      ...getColumnSearchProps(setFilterByTags, {
        type: 'multiselect',
        options: creativesTags.map(tag => ({
          label: tag,
          value: tag,
        })),
        placeholder: 'Tags',
      }),
    },
    {
      title: t('CreativesListPage.Table.Preview Image'),

      dataIndex: 'previewImgUrl',

      render: displayPreviewImageUrl =>
        displayPreviewImageUrl ? (
          <>
            <img src={displayPreviewImageUrl} alt="" width="100" style={{ height: 'auto' }} />
          </>
        ) : (
          <>❌</>
        ),
    },
    {
      title: t('CreativesListPage.Table.Body Image'),
      dataIndex: 'bodyImgUrl',
      render: bodyImgPath =>
        bodyImgPath ? (
          <>
            <img src={bodyImgPath} alt="" width="100" style={{ height: 'auto' }} />
          </>
        ) : (
          <>❌</>
        ),
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_, creative) => {
        return (
          <Space size="large">
            <EditCreativeIcon refetch={creativesQuery.refetch} creative={creative} />
            <ChangeCreativeStatus refetch={creativesQuery.refetch} creative={creative} />
            <DeleteCreativeIcon refetch={creativesQuery.refetch} id={creative.id} />
          </Space>
        )
      },
    },
  ]

  // Show userId in table only for Admin user
  if (user && user.isAdmin) {
    columns = [
      columns[0],
      columns[1],
      {
        title: 'User ID',
        dataIndex: 'userId',
        render: userId => <Link to={`/admin/users/${userId}`}>{userId}</Link>,
      },
      ...columns.slice(2),
    ]
  }

  const handleTableChange = (p: { current?: number; pageSize?: number }) => {
    const { current, pageSize } = p
    if (!current || !pageSize) {
      return
    }
    setPageNumber(current - 1)
    setPageSize(pageSize)
  }

  return (
    <>
      <Table
        // @ts-ignore
        columns={columns}
        dataSource={creatives}
        loading={creativesQuery.isLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        onChange={handleTableChange}
        pagination={{
          pageSize,
          total: totalCount,
          pageSizeOptions: ['50', '100', '150'],
          showSizeChanger: true,
        }}
        footer={() => {
          return (
            <FixedTableFooter>
              <Space>
                <BulkCopyCreatives
                  allSelectedCreatives={allSelectedCreatives}
                  refetch={creativesQuery.refetch}
                  campaignsData={campaignsData}
                />
              </Space>
            </FixedTableFooter>
          )
        }}
      />
    </>
  )
}

const CreativesListPage = () => {
  const { t } = useTranslation()
  const [fts, setFts] = useState<string>()
  const [loading, setLoading] = useState(false)
  const handleSearch = (p: { target: { value: any } }) => {
    setFts(p.target.value)
    setLoading(true)
  }

  const handleDataLoaded = () => {
    setLoading(false)
  }

  return (
    <>
      <PageHeader title={t('CreativesListPage.Page Title')}></PageHeader>
      <Search onChange={handleSearch} style={{ width: 400 }} placeholder="id, title, body" loading={loading} />
      <CreativesList fts={fts} onDataLoaded={handleDataLoaded} />
    </>
  )
}

CreativesListPage.Variants = componentVariationsFabric(
  CreativesListPage,
  lazy(() => import('./CreativesListPage'))
)

export default CreativesListPage
