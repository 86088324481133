import { createApiRequest } from '../../../api/http-client'

/**
 * @returns {Promise<{address: string;}[]>}
 */
export const fetchDomains = async (region, trafficType) => {
  const data = await createApiRequest('POST', `/domain/get`, { region, trafficTypes: [trafficType] })

  return data
}

export const fetchPublishers = async () => {
  const data = await createApiRequest('POST', '/user/get', { isPublisher: true })

  return data
}
