import { initContract } from '@ts-rest/core';
import { auth } from './auth';

import { ErrorCode } from './common/error.enum';

import { user } from './user';
import { videoCreative } from './video-creative';
import { creative } from './creative';
import { blackWhiteList } from './black-white-list';
import { stream } from './stream';
import { campaign } from './campaign';
import { report } from './report';

import { UserStatus } from './common/user-status';

import { trafficBlockers } from './traffic-blockers';
import { UserRole } from './common/user-role';
import { CampaignStatus } from './common/campaign-status';
import { CampaignType } from './common/campaign-type';
import { UserNotificationType } from './common/user-notification-type';
import { StreamType } from './common/stream-type';

const c = initContract();

export const contract = c.router({
  auth,
  user,
  creative,
  stream,
  videoCreative,
  blackWhiteList,
  campaign,
  report,
  trafficBlockers,
});

export {
  TrafficBlockersData,
  TrafficBlockersCampaignData,
  TrafficBlockersStreamData,
  TrafficBlockersStreamSubstreamData,
  TrafficBlockersInterval,
  BlockersData,
} from './traffic-blockers';
export {
  ErrorCode,
  UserStatus,
  UserRole,
  UserNotificationType,
  CampaignStatus,
  CampaignType,
  StreamType,
};
