/* eslint-disable react/display-name */
import { Form, Input as AntInput, InputNumber } from 'antd'
import React, { forwardRef } from 'react'
import './Input.sass'
import LabelWithTooltip from '../LabelWithTooltip/LabelWithTooltip'
import cn from 'classnames'

const Input = forwardRef(
  (
    {
      label,
      name,
      isRequired = false,
      rules,
      validators,
      multiLineLabel = false,
      isNumber,
      type = 'string',
      labelTooltip,
      description: extra,
      placeholder,
      disabled = false,
      max,
      min = 0,
      className,
      defaultValue,
      ...props
    },
    ref
  ) => {
    function renderInput() {
      if (isNumber) {
        return (
          <InputNumber
            placeholder={placeholder || label}
            min={min}
            max={max}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )
        // } else if (name.includes('password')) { // [Pp]assword
      } else if (name.match(/password/i)) {
        return <AntInput.Password placeholder={placeholder || label} disabled={disabled} />
      } else {
        return <AntInput ref={ref} placeholder={placeholder || label} defaultValue={defaultValue} disabled={disabled} />
      }
    }

    return (
      <>
        <Form.Item
          extra={extra}
          label={labelTooltip ? <LabelWithTooltip label={label} labelTooltip={labelTooltip} /> : label}
          name={name}
          rules={[
            {
              required: isRequired,
              type: isNumber ? 'number' : type,
              ...rules,
            },
            ...(validators || []),
          ]}
          className={cn({
            [className]: !!className,
            ['multi-line-label']: !!multiLineLabel,
          })}
          {...props}
        >
          {renderInput()}
        </Form.Item>
      </>
    )
  }
)

export default Input
