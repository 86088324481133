import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const REPORT_CAMPAIGN_AUTOCOMPLETE_QUERY = gql`
  query ReportCampaignAutoCompleteQuery {
    reportCampaignAutoComplete {
      creatives {
        id
        title
        campaignId
        user {
          id
        }
      }
      campaigns {
        id
        title
        user {
          id
        }
      }
      targets {
        type
        values
      }
      users {
        id
        email
      }
      campaignTags
      creativeTags
    }
  }
`

export function useReportCampaignAutocompleteQuery() {
  const { loading, error, data, refetch } = useQuery(REPORT_CAMPAIGN_AUTOCOMPLETE_QUERY, {
    variables: {},
  })
  return { loading, error, data, refetch }
}
