import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { CampaignStatusEnum } from './common/campaign-status';
import { CampaignTypeEnum } from './common/campaign-type';
const c = initContract();

export const campaign = c.router({
  getCampaignListPaginated: {
    method: 'GET',
    path: '/campaign/getCampaignListPaginated',
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            userId: z.number(),
            title: z.string(),
            status: CampaignStatusEnum,
            price: z.number(),
            campaignType: CampaignTypeEnum,
            tags: z.string().array(),
            streams: z.string().array(),
            countryCode: z.string().array(),
            maxDailyBudget: z.number(),
            enabledNotificationType: z.array(
              z.enum(['DAILY_STAT', 'LOW_BUDGET']),
            ),
            spent: z.number().optional(),
            streamsInfo: z.array(
              z.object({
                id: z.number(),
                title: z.string(),
              }),
            ),
            streamIds: z.number().array(),
          })
          .array(),
        filterValues: z.object({
          streamIds: z.number().array(),
          tags: z.string().array(),
          ids: z.number().array(),
          countries: z.string().array(),
          userIds: z.number().array(),
          statuses: CampaignStatusEnum.array(),
        }),
        pagination: paginationResponse,
      }),
      500: z.object({
        message: z.string(),
      }),
    },
    query: z.object({
      filter: z
        .object({
          title: z.string().optional(),
          ids: z.array(z.coerce.number()).optional(),
          userIds: z.array(z.coerce.number()).optional(),
          streamIds: z.array(z.coerce.number()).optional(),
          statuses: CampaignStatusEnum.array().optional(),
          countries: z.array(z.string()).optional(),
          campaignTypes: CampaignTypeEnum.array().optional(),
          spent: z.boolean().optional(),
          search: z.string().optional(),
          tags: z.array(z.string()).optional(),
          enabledNotificationType: z
            .array(z.enum(['DAILY_STAT', 'LOW_BUDGET']))
            .optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
  },
  bulkPauseCampaignCreatives: {
    method: 'POST',
    path: '/creative/bulkPauseCampaignCreatives',
    body: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        pausedCreativesIds: z.number().array(),
      }),
    },
  },
  bulkDeleteCampaignCreatives: {
    method: 'POST',
    path: '/creative/bulkDeleteCampaignCreatives',
    body: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        deletedCreativesIds: z.number().array(),
      }),
    },
  },
});
