import PageHeader from '../components/layout/PageHeader/PageHeader'
import Inventory from '../containers/inventory/Inventory'

const InventoryPage = () => {
  return (
    <>
      <PageHeader title="Inventory"></PageHeader>

      <Inventory />
    </>
  )
}

export default InventoryPage
