import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export type BlockedByFeilds =
  | 'totalRequests'
  | 'blockedByStream'
  | 'blockedBySubstream'
  | 'blockedByCountry'
  | 'blockedByLang'
  | 'blockedByDevice'
  | 'blockedByOs'
  | 'blockedByBrowser'
  | 'blockedByOsVersion'
  | 'blockedByBrowserVersion'
  | 'blockedByConnectionType'
  | 'blockedByDomain'
  | 'blockedByIsp';

export interface BlockersData extends Record<BlockedByFeilds, number> {}

export interface TrafficBlockersCampaignData extends BlockersData {
  campaign_id: number;
  date: string;
}

export interface TrafficBlockersStreamData extends BlockersData {
  campaign_id: number;
  stream: string;
  date: string;
}

export interface TrafficBlockersStreamSubstreamData extends BlockersData {
  campaign_id: number;
  stream: string;
  substream_id: string;
  date: string;
}

export type TrafficBlockersData =
  | Partial<TrafficBlockersCampaignData>
  | Partial<TrafficBlockersStreamData>
  | Partial<TrafficBlockersStreamSubstreamData>;

export const TRAFFIC_BLOCKERS_INTERVALS = {
  hour: 'hour' as const,
  day: 'day' as const,
  week: 'week' as const,
  month: 'month' as const,
} as const;

export type TrafficBlockersInterval =
  (typeof TRAFFIC_BLOCKERS_INTERVALS)[keyof typeof TRAFFIC_BLOCKERS_INTERVALS];

export const trafficBlockers = c.router({
  getTrafficBlockers: {
    method: 'GET',
    path: '/campaign/trafficBlockers',
    query: z.object({
      campaignId: z.coerce.number(),
      stream: z.string().optional(),
      substream: z.string().optional(),
      interval: z
        .enum(['hour', 'day', 'week', 'month'])
        .optional()
        .default('week'),
    }),
    responses: {
      200: z.object({
        total: z.coerce.number(),
        items: z.record(z.string(), z.coerce.number()),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
