import { z } from 'zod';
import { initContract } from '@ts-rest/core';

import { UserStatus, UserStatusEnum } from './common/user-status';

import { ErrorCode } from './common/error.enum';
import { UserRoleEnum } from './common/user-role';
import { UserNotificationTypeEnum } from './common/user-notification-type';

const c = initContract();

export const user = c.router({
  confirmEmailCode: {
    method: 'POST',
    path: '/user.confirmEmailCode',
    responses: {
      201: z.unknown(),
      400: z.object({
        errorCode: z.enum([ErrorCode.VERIFICATION_CODE_INVALID]),
        message: z.string().optional(),
      }),
    },
    body: z.object({
      code: z.string(),
      email: z.string().email(),
    }),
  },

  resendConfirmationEmailCode: {
    method: 'POST',
    path: '/user.resendConfirmationEmailCode',
    responses: {
      201: z.unknown(),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.VERIFICATION_CODE_INVALID,
          ErrorCode.VERIFICATION_CODE_TOO_MANY_REQUEST,
        ]),
        message: z.string().optional(),
      }),
    },
    body: z.object({
      email: z.string().email(),
    }),
  },

  getUserListPaginated: {
    method: 'GET',
    path: '/user/getUserListPaginated',
    query: z.object({
      filter: z
        .object({
          ftsByEmail: z.string().optional(),
          isActive: z.boolean().optional(),
          isPublisher: z.boolean().optional(),
          isAdvertiser: z.boolean().optional(),
          statuses: z.nativeEnum(UserStatus).array().optional(),
        })
        .default({}),
      pagination: z.object({ limit: z.number(), offset: z.number() }),
    }),
    responses: {
      500: z.object({
        message: z.string(),
      }),
      200: z.object({
        items: z
          .object({
            id: z.number(),
            roles: UserRoleEnum.array(),
            name: z.string().nullable(),
            email: z.string().email(),
            isActive: z.boolean(),
            isPublisher: z.boolean(),
            isAdvertiser: z.boolean(),
            preferredContact: z.string().nullable(),
            status: UserStatusEnum,
            fee: z.number(),
          })
          .array(),
        pagination: z.object({
          limit: z.number(),
          offset: z.number(),
          total: z.number(),
        }),
      }),
    },
  },

  getUserById: {
    method: 'GET',
    path: '/user/getUserById',
    query: z.object({
      userId: z.number(),
    }),
    responses: {
      200: z
        .object({
          id: z.number(),
          name: z.string().nullable(),
          email: z.string().email(),
          isEmailConfirmed: z.boolean(),
          isActive: z.boolean(),
          isPublisher: z.boolean().nullable(),
          isAdvertiser: z.boolean().nullable(),
          isAdmin: z.boolean().nullable(),
          updatedAt: z.string().nullable(),
          postback: z.string().nullable(),
          fee: z.number(),
          preferredContact: z.string().nullable(),
          roles: UserRoleEnum.array(),
          createdAt: z.string(),
        })
        .required(),
    },
  },

  updateSettings: {
    method: 'POST',
    path: '/updateSettings',
    body: z.object({
      email: z.string().email().optional(),
      name: z.string().optional(),
    }),
    responses: {
      201: z.object({}),
      404: z.object({}),
    },
  },

  getMe: {
    method: 'GET',
    path: '/user/me',
    responses: {
      200: z.object({
        id: z.number(),
        name: z.string().nullable(),
        email: z.string().email(),
        postback: z.string().nullable(),
        telegram: z.string().nullable(),
        isAdmin: z.boolean().nullable(),
        isPublisher: z.boolean().nullable(),
        isAdvertiser: z.boolean().nullable(),
        isActive: z.boolean().nullable(),
        featureFlags: z.string().array().nullable(),
        isEmailConfirmed: z.boolean(),
        activeNotificationTypes: UserNotificationTypeEnum.array(),
        roles: UserRoleEnum.array(),
        updatedAt: z.string().nullable(),
        createdAt: z.string(),
      }),
    },
  },

  updateUser: {
    method: 'POST',
    path: '/user.update',
    body: z.object({
      id: z.number(),
      name: z.string().optional().optional(),
      password: z.string().optional(),
      preferredContact: z.string().optional(),
      roles: UserRoleEnum.array().optional(),
      isEmailConfirmed: z.boolean().optional(),
      fee: z.coerce.number().lte(100).gte(0).int().optional(),
      isActive: z.boolean().optional(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_NOT_FOUND]),
        message: z.string().optional(),
      }),
    },
  },

  addUser: {
    method: 'POST',
    path: '/user.add',
    body: z.object({
      name: z.string().optional(),
      password: z.string(),
      preferredContact: z.string().optional(),
      email: z.string().email(),
      roles: UserRoleEnum.array(),
      isEmailConfirmed: z.boolean(),
      isActive: z.boolean(),
      fee: z.coerce.number().lte(100).gte(0).int().optional(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_IS_REGISTERED]),
        message: z.string().optional(),
      }),
    },
  },

  getNotificationSettings: {
    method: 'GET',
    path: '/user/settings/notification',
    responses: {
      200: z.object({
        telegramNotificationEnabled: z.boolean(),
      }),
    },
  },
  updateTelegramNotificationSettings: {
    method: 'PATCH',
    path: '/user/settings/notification/telegram',
    body: z.object({
      enabled: z.boolean(),
    }),
    responses: {
      200: z.object({ ok: z.boolean() }),
    },
  },

  hasNewUsers: {
    method: 'GET',
    path: '/user/hasNewUsers',
    responses: {
      200: z.object({
        hasNewUsers: z.boolean(),
      }),
    },
  },

  changeUserStatus: {
    method: 'POST',
    path: '/user/changeUserStatus',
    body: z.object({
      userId: z.number(),
      status: UserStatusEnum,
    }),
    responses: {
      200: z.object({ ok: z.boolean() }),
    },
  },
});
