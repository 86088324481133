import { Form } from 'antd'
import React, { FC } from 'react'
import Title from '../../layout/Title/Title'
import './FormSubHeader.sass'

const FormSubHeader: FC<{
  title: string
  description?: string
  layout: any
  right?: any
  level?: number
}> = ({ title, layout, description, right, level = 5, ...props }) => {
  return (
    <Form.Item {...layout}>
      <Title isSeparator={false} level={level} {...props}>
        {title}
        {right && <div style={{ float: 'right' }}>{right}</div>}
      </Title>
      {description && <p style={{ marginTop: '-4px' }}>{description}</p>}
    </Form.Item>
  )
}

export default FormSubHeader
