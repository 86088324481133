export enum ErrorCode {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  USER_IS_REGISTERED = 'USER_IS_REGISTERED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID',
  VERIFICATION_CODE_TOO_MANY_REQUEST = 'VERIFICATION_CODE_TOO_MANY_REQUEST',
  EMAIL_NOT_CONFIRMED = 'EMAIL_NOT_CONFIRMED',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  CREATIVE_NOT_APPROVED = 'CREATIVE_NOT_APPROVED',
}
