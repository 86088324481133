import React, { lazy, useEffect, useState } from 'react'
import { Row, Form, Col, Button, FormProps, Input } from 'antd'

import Title from '../../components/layout/Title/Title'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { defaultFormLayout } from '../../helpers/formLayouts'
import componentVariationsFabric from '../../helpers/components-fabric.helper'
import { DEFAULT_TITLE } from '../../constant'
import { useQueryClient } from '@tanstack/react-query'
import { useAppState } from '../../context/AppContext'

import { ErrorCode } from '@pushflow/backend-http-contract'

import showNotification from '../../helpers/showNotification'

// @ts-ignore
export const LoginPage = ({ updateToken }) => {
  const queryClient = useQueryClient()
  const { apiClient } = useAppState()
  const [showVerificationCodeForm, setShowVerificationCodeForm] = useState(false)

  const { t } = useTranslation()
  const [userInfo, setUserInfon] = useState<{ email: string; password: string } | null>(null)

  // @ts-ignore
  const onLogin = async ({ email, password }) => {
    setUserInfon({ email: email, password: password })
    // @ts-ignore
    const { status, body } = await apiClient.auth.login.mutation({ body: { email, password } })

    if (status === 400) {
      if (body.errorCode === ErrorCode.EMAIL_NOT_CONFIRMED) {
        setShowVerificationCodeForm(true)
      }
      if (body.errorCode === ErrorCode.PASSWORD_INVALID) {
        showNotification({ type: 'error', message: 'Invalid password' })
      }
    }

    if (status === 201) {
      await updateToken(body.jwtToken)

      queryClient.clear()
    }
  }

  const confirmEmailCode: FormProps<{
    code: string
  }>['onFinish'] = async formProps => {
    if (userInfo) {
      const { body, status } = await apiClient.user.confirmEmailCode.mutation({
        body: { email: userInfo?.email, code: formProps.code },
      })
      if (status === 400) {
        if (body.errorCode === ErrorCode.VERIFICATION_CODE_INVALID) {
          showNotification({
            type: 'error',
            message: 'Verification code invalid',
          })
        }
      }
      if (status === 201 && userInfo) {
        const { status, body } = await apiClient.auth.login.mutation({
          body: { email: userInfo.email, password: userInfo.password },
        })
        if (status === 201) {
          await updateToken(body.jwtToken)
          queryClient.clear()
        }
      }
    }
  }

  // @ts-ignore
  const resendConfirmationEmailCode = async e => {
    // @ts-ignore
    e.preventDefault()
    if (userInfo) {
      const { body, status } = await apiClient.user.resendConfirmationEmailCode.mutation({
        body: { email: userInfo?.email },
      })

      if (status === 400) {
        if (body.errorCode === ErrorCode.VERIFICATION_CODE_TOO_MANY_REQUEST) {
          showNotification({
            type: 'warning',
            message: 'Stop',
            description: 'cCde request can be repeated after 1 minute',
          })
        }
      }
    }
  }

  useEffect(() => {
    document.title = `Login`

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [])

  return (
    <>
      <Form hidden={showVerificationCodeForm} {...defaultFormLayout} onFinish={onLogin}>
        <Row>
          <Col offset={12}>
            <Title level={3} isSeparator={true}>
              {t('LoginPage.Login title')}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={8}>
            <Form.Item
              label={t('Common.Email' as const)}
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={8}>
            <Form.Item
              label={t('Common.Password' as const)}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" wrap={false}>
          <Col offset={3} span={3}>
            <Button type="primary" htmlType="submit">
              {t('LoginPage.Login button')}
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col offset={3} span={3}>
            <Link to="/resetpassword">{t('LoginPage.Forgot password')}</Link>
          </Col>
        </Row>
      </Form>

      <Form hidden={!showVerificationCodeForm} {...defaultFormLayout} onFinish={confirmEmailCode}>
        <Form.Item
          label={'Code'}
          name="code"
          rules={[{ required: true, max: 6, message: 'Please input verification code!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Confirm email code
          </Button>
          <Button type="link" block onClick={resendConfirmationEmailCode}>
            Resend code
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

// @ts-ignore
LoginPage.Variants = componentVariationsFabric(
  // @ts-ignore
  LoginPage,
  lazy(() => import('./login.page').then(({ LoginPage }) => ({ default: LoginPage })))
)
