import { gql } from '@apollo/client'
import { useQuery } from '@apollo/react-hooks'
import { USER_AUDITORY_DATA } from '../UserAuditory/operations.js'

export const GET_USER_AUDITORY_QUERY = gql`
  query GetUserAuditory($input: GetUserAuditoryInput!) {
    getUserAuditory(input: $input) {
      userAuditory {
        ...UserAuditoryData
      }
      usersCounter
    }
  }
  ${USER_AUDITORY_DATA}
`

export const useGetUserAuditoryQuery = (input, options = {}) => {
  return useQuery(GET_USER_AUDITORY_QUERY, {
    variables: { input },
    ...options,
  })
}
