import { contract } from '@pushflow/backend-http-contract'
import { initQueryClient } from '@ts-rest/react-query'
import React from 'react'

export type ApiClient = ReturnType<typeof initQueryClient<typeof contract, { baseUrl: '' }>>

const AppContext = React.createContext<{
  token: string | null
  setToken: (token: string) => void
  user: any
  refetchUser: () => void
  lang: string
  updateLang: (lang: string) => void
  setStatisticDays: (days: number) => void
  statisticDays: number
  apiClient: ApiClient
}>({
  token: null,
  setToken: () => {
    //
  },
  refetchUser: () => {
    //
  },
  user: {},
  lang: '',
  updateLang: () => {
    //
  },
  statisticDays: 14,
  setStatisticDays: () => {
    //
  },
  apiClient: {} as ApiClient,
})

function useAppState() {
  const context = React.useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppContext')
  }
  return context
}

export { AppContext, useAppState }
