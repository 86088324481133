import { gql, useMutation } from '@apollo/client'

export const ADD_USER_TO_AUDITORY_MUTATION = gql`
  mutation AddUserToAuditory($input: AddUserToAuditoryInput!) {
    addUserToAuditory(input: $input) {
      success
    }
  }
`

export const useAddUserToAuditoryMutation = (options = {}) => {
  return useMutation(ADD_USER_TO_AUDITORY_MUTATION, options)
}
