import { Button, Checkbox, Form, Input } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../../helpers/formLayouts'
import showNotification from '../../../../helpers/showNotification'
import { useAppState } from '../../../../context/AppContext'
import React from 'react'
import { ErrorCode, UserRole } from '@pushflow/backend-http-contract'

interface FormValues {
  email: string
  isActive: boolean
  isEmailConfirmed: boolean
  isAdvertiser: boolean
  isPublisher: boolean
  name?: string
  password: string
  preferredContact?: string
  isInternalAdvertiser: boolean
  isExternalAdvertiser: boolean
  fee?: number
}

const percentageValidator = (_: any, value: number): Promise<void> => {
  if (value === undefined) {
    return Promise.resolve()
  }

  if (value < 0 || value > 100) {
    return Promise.reject(new Error('Please enter an integer between 0 and 100'))
  }

  return Promise.resolve()
}

export const UserCreateForm = () => {
  const navigate = useNavigate()
  const { apiClient } = useAppState()
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const [showFeeInput, setShowFeeInput] = useState<boolean>(false)

  const onCheckboxChange = (e: any) => {
    setShowFeeInput(e.target.checked)
  }

  const isExternalAdvertiser = Form.useWatch('isExternalAdvertiser', form)

  const initialValues: FormValues = {
    email: '',
    password: '',
    isActive: false,
    isEmailConfirmed: false,
    isPublisher: false,
    isAdvertiser: false,
    isInternalAdvertiser: false,
    isExternalAdvertiser: false,
  }

  const submit = async (values: FormValues) => {
    const roles: UserRole[] = []

    if (values.isInternalAdvertiser) {
      roles.push(UserRole.INTERNAL_ADVERTISER)
    }

    if (values.isExternalAdvertiser) {
      roles.push(UserRole.EXTERNAL_ADVERTISER)
    }

    if (values.isPublisher) {
      roles.push(UserRole.PUBLISHER)
    }

    const { status, body } = await apiClient.user.addUser.mutation({ body: { ...values, roles } })

    if (status === 200) {
      showNotification({ type: 'success', message: 'User successfully created!' })
      navigate(`/admin/users/${body.id}`)
    } else if (status === 400) {
      if (body.errorCode === ErrorCode.USER_IS_REGISTERED) {
        showNotification({
          type: 'warning',
          message: 'User is registered',
        })
        setSubmitting(false)
      }
    }
  }

  return (
    <Form<FormValues>
      form={form}
      onFinish={submit}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
      >
        <Input required />
      </Form.Item>
      <Form.Item name="password" label="Password">
        <Input required />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="preferredContact" label="Preferred contact">
        <Input />
      </Form.Item>
      <Form.Item label="Active" name="isActive" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Is email confirmed" name="isEmailConfirmed" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Publisher" name="isPublisher" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Inteam advertiser" name="isInternalAdvertiser" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="External advertiser" name="isExternalAdvertiser" valuePropName="checked">
        <Checkbox onChange={onCheckboxChange}>Enabled</Checkbox>
      </Form.Item>
      {showFeeInput && (
        <Form.Item
          name="fee"
          label="Fee"
          rules={[{ required: true, message: 'Please enter a percentage value' }, { validator: percentageValidator }]}
          initialValue={isExternalAdvertiser === true ? 30 : 0}
        >
          <Input type={'number'} required />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayoutOffset}>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Create
        </Button>
      </Form.Item>
    </Form>
  )
}
