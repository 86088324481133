import { DeleteTwoTone, SearchOutlined } from '@ant-design/icons'
import { useDeleteUserAuditoryMutation } from '@pushflow/server/src/graphql/new/userAuditories/deleteUserAuditory/operations'
import { useGetUserAuditoriesQuery } from '@pushflow/server/src/graphql/new/userAuditories/getUserAuditories/operations'
import { useUserAuditoryFiltersQuery } from '@pushflow/server/src/graphql/new/userAuditories/getUserAuditoryFilters/operations'
import { Alert, Button, Input, Space, Table } from 'antd'
import React, { lazy, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../../components/buttons/DeleteButton/DeleteButton'
import componentVariationsFabric from '../../../../helpers/components-fabric.helper'
import showNotification from '../../../../helpers/showNotification'
import { useAppState } from '../../../../context/AppContext'
import Loading from '../../../../components/Loading'

const DeleteUserAuditoryIcon = ({ id, refetch }) => {
  const { mutate: deleteUserAuditory } = useDeleteUserAuditoryMutation()
  return (
    <DeleteButton
      key="3"
      isOnlyIcon
      onClick={() => {
        deleteUserAuditory({
          variables: { input: { id } },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteUserAuditory: {
              id,
              isSuccess: true,
              __typename: 'isSuccess',
            },
          },
        }).then(() => {
          showNotification({
            type: 'success',
            message: 'User auditory successfully deleted',
          })
          refetch()
        })
      }}
    >
      <DeleteTwoTone />
    </DeleteButton>
  )
}

const UserAuditoriesList = () => {
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [limit, setLimit] = useState(10)
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [offset, setOffset] = useState(0)
  const [title, setTitle] = useState()

  const { t } = useTranslation()
  const { user } = useAppState()

  const { loading: filtersLoading, data: filtersData } = useUserAuditoryFiltersQuery()

  const { loading, error, data, refetch } = useGetUserAuditoriesQuery({
    limit,
    offset,
    title,
  })
  const showLoading = (loading && !data) || (filtersLoading && !filtersData)
  if (showLoading) return <Loading />

  if (error) return <Alert type="error" message={error.message} />

  let searchInput

  const getColumnSearchProps = (dataIndex, setter) => {
    const _handler = (values, callback) => {
      setter(values)

      callback()
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
            onPressEnter={() => _handler(selectedKeys[0], confirm)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => _handler(selectedKeys[0], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                _handler(undefined, clearFilters)
                confirm()
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false })
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : undefined,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
      render: (text, record) => (
        <Link to={`/campaign/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    }
  }

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text, record) => (
        <Link to={`/userauditory/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps('title', v => setTitle((v || '').toLowerCase())),
      render: (text, record) => (
        <Link to={`/userauditory/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="large">
            <DeleteUserAuditoryIcon id={record.id} refetch={refetch} />
          </Space>
        )
      },
    },
  ]

  if (user && user.isAdmin) {
    columns = [
      columns[0],
      {
        title: 'User ID',
        dataIndex: 'userId',
        filters: [...(filtersData.userAuditoryFilters?.userIds || [])].sort().map(value => ({ text: value, value })),
        filterSearch: true,
        onFilter: (value, record) => {
          return +record.userId === +value
        },
        render: text => <Link to={`/admin/users/${text}`}>{text}</Link>,
        width: 100,
      },
      ...columns.slice(1),
    ]
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.getUserAuditories.userAuditories || []}
        loading={showLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        pagination={{
          pageSize: limit,
          total: data?.getUserAuditories.total || 0,
          pageSizeOptions: [10, 20, 50, 100, 400],
          showSizeChanger: true,
          current: offset + 1,
          onChange(page, newLimit) {
            if (newLimit !== limit) {
              const firstItemIndexInCurrentPage = offset * limit
              const pageWhereItemWillBeAfterNewLimit = Math.floor(firstItemIndexInCurrentPage / newLimit)
              setOffset(pageWhereItemWillBeAfterNewLimit)
              setLimit(newLimit)
            } else if (page - 1 !== offset) {
              setOffset(page - 1)
            }
          },
        }}
      />
    </>
  )
}

UserAuditoriesList.Variants = componentVariationsFabric(
  UserAuditoriesList,
  lazy(() => import('./UserAuditoriesList'))
)

export default UserAuditoriesList
