import Title from '../../components/layout/Title/Title'
import { AdvertiserStats } from './components/advertiser-stats'
import { PublisherStats } from './components/publisher-stats'
import React, { FC, lazy } from 'react'
import componentVariationsFabric from '../../helpers/components-fabric.helper'

export const DashboardPage: FC<{ user: any }> = ({ user }) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Title level={2} style={{ marginBottom: 28 }} isSeparator={true}>
          Dashboard
        </Title>

        <div style={{ display: 'flex', flexFlow: 'column', gap: 40 }}>
          {(user.isPublisher || user.isAdmin) && <PublisherStats />}
          {(user.isAdvertiser || user.isAdmin) && <AdvertiserStats />}
        </div>
      </div>
    </>
  )
}

// @ts-ignore
DashboardPage.Variants = componentVariationsFabric(
  // @ts-ignore
  DashboardPage,
  lazy(() => import('./dashboard.page').then(({ DashboardPage }) => ({ default: DashboardPage })))
)
