import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const USER_AUDITORY_QUERY_FRAGMENT_NAMES_ENUM = Object.freeze({
  filters: 'UsreAuditoryFiltersData',
})

export const USER_AUDITORY_FILTERS_DATA = gql`
  fragment ${USER_AUDITORY_QUERY_FRAGMENT_NAMES_ENUM.filters} on UserAuditoryFilters {
    __typename
    userIds
  }
`

export const USER_AUDITORY_FILTERS_QUERY = gql`
  query UserAuditoryFiltersQuery {
    userAuditoryFilters {
      ...${USER_AUDITORY_QUERY_FRAGMENT_NAMES_ENUM.filters}
    }
  }
  ${USER_AUDITORY_FILTERS_DATA}
`

export function useUserAuditoryFiltersQuery() {
  return useQuery(USER_AUDITORY_FILTERS_QUERY)
}
