import { Input, Popconfirm } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useState } from 'react'

import showNotification from '../../../helpers/showNotification'
import { useMutation } from '@tanstack/react-query'
import { createApiRequest } from '../../../api/http-client'

export const EditCampaignDailyBudget = ({ dailyBudget, campaignId, refetch, queryClient }) => {
  const mutation = useMutation({
    mutationFn: async value => {
      await createApiRequest('PATCH', `/campaign/settings/daily-budget`, {
        dailyBudget: value,
        campaignId: Number(campaignId),
      })

      showNotification({
        type: 'success',
        message: `Daily budget changed for campaign ${campaignId}`,
      })
    },
  })

  const onConfirm = async value => {
    await mutation.mutateAsync(value)
    refetch()
    queryClient.invalidateQueries({ queryKey: ['campaigns'] })
  }

  const [priceValue, setPriceValue] = useState(dailyBudget)

  if (mutation.isError) {
    showNotification({
      type: 'error',
      message: `Error changing daily budget for campaign ${campaignId}`,
    })
  }

  return (
    <Popconfirm
      title={
        <div>
          <span>Change daily budget for campaign {campaignId}</span>
          <Input defaultValue={priceValue} onChange={e => setPriceValue(e.target.value)} />
        </div>
      }
      onConfirm={() => onConfirm(parseFloat(priceValue))}
      okText="Change Budget"
      cancelText="Cancel"
      placement="bottom"
    >
      <EditTwoTone />
    </Popconfirm>
  )
}
