import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { USER_AUDITORY_DATA } from '../UserAuditory/operations.js'

export const CREATE_OR_UPDATE_USER_AUDITORY_MUTATION = gql`
  mutation UpdateUserAuditory($input: UpdateUserAuditoryInput!) {
    createOrUpdateUserAuditory(input: $input) {
      userAuditory {
        ...UserAuditoryData
      }
    }
  }
  ${USER_AUDITORY_DATA}
`

export const useCreateOrUpdateUserAuditoryMutation = (options = {}) => {
  const [mutate, { data, error }] = useMutation(CREATE_OR_UPDATE_USER_AUDITORY_MUTATION, {
    ...options,
  })

  return { mutate, data, error }
}
