import { DeleteTwoTone, SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Input, Space, Table, Tag, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../../components/buttons/DeleteButton/DeleteButton'
import showNotification from '../../../../helpers/showNotification'
import { useAppState } from '../../../../context/AppContext'
import Loading from '../../../../components/Loading'

const DeleteBlackWhiteListIcon: FC<{ id: number; refetch: any }> = ({ id, refetch }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: deleteList } = apiClient.blackWhiteList.deleteList.useMutation()
  return (
    <DeleteButton
      key="3"
      isOnlyIcon
      onClick={() => {
        deleteList({ body: { id } }).then(() => {
          showNotification({
            type: 'success',
            message: 'List successfully deleted',
          })
          refetch()
        })
      }}
    >
      <DeleteTwoTone {...({} as any)} />
    </DeleteButton>
  )
}

const BlackWhiteLists = () => {
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [title, setTitle] = useState<string>()

  // const searchInputEl = useRef(null)
  const { t } = useTranslation()
  const { user, apiClient } = useAppState()

  const { isLoading, error, data, refetch } = apiClient.blackWhiteList.getListListPaginated.useQuery(
    ['blackWhiteListList', offset, limit, title],
    {
      query: {
        // filter: title ? { title } : {},
        offset,
        limit,
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [title, limit, offset])

  const showLoading = isLoading && !data
  if (showLoading) return <Loading />

  if (error) return <Alert type="error" message={error.body?.toString()} />

  let searchInput: any

  const getColumnSearchProps = (dataIndex: string, setter: any) => {
    const _handler = (values: any, callback: any) => {
      setter(values)

      callback()
    }

    return {
      filterDropdown: (p: { setSelectedKeys: any; selectedKeys: string[]; confirm: any; clearFilters: any }) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = p
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
              onPressEnter={() => _handler(selectedKeys[0], confirm)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => _handler(selectedKeys[0], confirm)}
                icon={<SearchOutlined {...({} as any)} />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  _handler(undefined, clearFilters)
                  confirm()
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false })
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        )
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined {...({} as any)} style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : undefined,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100)
        }
      },
      render: (text: string, record: any) => (
        <Link to={`/campaign/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    }
  }

  let columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string, record: any) => (
        <Link to={`/subscriptionlist/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps('title', (v: string) => setTitle((v || '').toLowerCase())),
      render: (text: string, record: any) => (
        <Link to={`/subscriptionlist/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: 'Type',
      dataIndex: 'listType',
      render: (text: string) => <Tag color={text === 'STREAM' ? 'purple' : 'blue'}>{text}</Tag>,
    },
    {
      title: 'Blacklisted in',
      dataIndex: 'blcampaigns',
      render: (val: Array<{ campaignId: number; title: string }>) =>
        val && val.length > 0 ? (
          val.map(v => (
            <Tooltip key={v.campaignId} title={v.title}>
              <Tag>{v.campaignId}</Tag>
            </Tooltip>
          ))
        ) : (
          <>-</>
        ),
    },
    {
      title: 'Whitelisted in',
      dataIndex: 'wlcampaigns',
      render: (val: Array<{ campaignId: number; title: string }>) =>
        val && val.length > 0 ? (
          val.map(v => (
            <Tooltip key={v.campaignId} title={v.title}>
              <Tag>{v.campaignId}</Tag>
            </Tooltip>
          ))
        ) : (
          <>-</>
        ),
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_: any, record: any) => {
        return (
          <Space size="large">
            <DeleteBlackWhiteListIcon id={record.id} refetch={refetch} />
          </Space>
        )
      },
    },
  ]

  if (user && user.isAdmin) {
    columns = [
      columns[0],
      {
        title: 'User ID',
        dataIndex: 'userId',
        filters: [...(data?.body.filterValues.userIds || [])].sort().map(value => ({ text: value, value })),
        filterSearch: true,
        onFilter: (value: any, record: any) => {
          return +record.userId === +value
        },
        render: (text: string) => <Link to={`/admin/users/${text}`}>{text}</Link>,
        width: 100,
      },
      ...columns.slice(1),
    ]
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.body.items || []}
        loading={showLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        pagination={{
          pageSize: limit,
          total: data?.body.total || 0,
          pageSizeOptions: [10, 20, 50, 100, 400],
          showSizeChanger: true,
          current: offset / limit + 1,
          onChange(page, newLimit) {
            if (newLimit !== limit) {
              const firstItemIndexInCurrentPage = offset * limit
              const pageWhereItemWillBeAfterNewLimit = Math.floor(firstItemIndexInCurrentPage / newLimit)
              setOffset(pageWhereItemWillBeAfterNewLimit * newLimit)
              setLimit(newLimit)
            } else if (page - 1 !== offset) {
              setOffset((page - 1) * limit)
            }
          },
        }}
      />
    </>
  )
}

export default BlackWhiteLists
