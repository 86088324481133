import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { USER_AUDITORY_DATA } from '../UserAuditory/operations.js'

export const GET_USER_AUDITORIES_QUERY = gql`
  query GetUsreAuditories($input: GetUserAuditoriesInput!) {
    getUserAuditories(input: $input) {
      userAuditories {
        ...UserAuditoryData
      }
      total
    }
  }
  ${USER_AUDITORY_DATA}
`

export const useGetUserAuditoriesQuery = (input = {}, options = {}) => {
  return useQuery(GET_USER_AUDITORIES_QUERY, {
    variables: { input },
    ...options,
  })
}
