import { gql, useMutation } from '@apollo/client'

export const DELETE_USER_FROM_AUDITORY_MUTATION = gql`
  mutation DeleteUserFromAuditory($input: DeleteUserFromAuditoryInput!) {
    deleteUserFromAuditory(input: $input) {
      success
    }
  }
`

export const useDeleteUserFromAuditoryMutation = (options = {}) => {
  return useMutation(DELETE_USER_FROM_AUDITORY_MUTATION, options)
}
