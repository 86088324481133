import { Form, Input, Modal, Select, Spin, UploadProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import showNotification from '../../../helpers/showNotification'
import LabelWithTooltip from '../../../components/forms/LabelWithTooltip/LabelWithTooltip'
import React from 'react'

import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { getToken } from '../../../helpers/jwtHelper'
import { Player } from 'video-react'
import config from '../../../config'
import { useAppState } from '../../../context/AppContext'
import { ICreative } from '../../push/CreativesListPage'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

export interface VideoCreative extends ICreative {
  tags: string[] | null
  videoFile: { id: string | null; key: string; downloadUrl: string } | null
}

export const VideoCreativeForm: FC<{
  creative?: VideoCreative
  campaignId: number
  visible: boolean
  setVisible: any
  refetch: () => void
}> = ({ creative = {} as VideoCreative, campaignId, visible, setVisible, refetch }) => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const initialValues: VideoCreative = {
    ...creative,
    videoFile: creative?.videoFile || { id: '', key: '', downloadUrl: '' },
    tags: creative?.tags || [],
  }

  const creativeId = creative && creative.id
  const getCreativesTagsResult = apiClient.creative.getCreativesTags.useQuery([])

  const [videoFile, setVideoFile] = useState(creative?.videoFile?.downloadUrl)
  const [uploadedVideoFileId, setUploadedVideoFileId] = useState<string | undefined | null>(initialValues.videoFile?.id)
  const creativesTags = getCreativesTagsResult.data?.body.tags || []

  useEffect(() => {
    if (visible) {
      getCreativesTagsResult.refetch()
    }
  }, [visible])

  const { mutateAsync: saveCreative } = creativeId
    ? apiClient.creative.updateCreative.useMutation()
    : apiClient.creative.createCreative.useMutation()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [JSON.stringify(initialValues)])

  async function onFinish(formValues: any) {
    setLoading(true)

    if (!uploadedVideoFileId) {
      setLoading(false)
      showNotification({ type: 'error', message: 'Video is required' })
      return
    }

    const fd = new FormData()
    if (creativeId) {
      fd.append('id', creativeId.toString())
    }
    fd.append('campaignId', `${campaignId}`)
    fd.append('title', formValues.title)
    fd.append('tags', formValues.tags)
    fd.append('videoFileId', uploadedVideoFileId)

    saveCreative({
      body: fd,
      extraHeaders: {
        'Content-Type': undefined,
      },
    })
      .then(() => {
        showNotification({ type: 'success', message: t('Messages.Saved') })
        setVisible(false)
        setLoading(false)
        refetch()
      })
      .catch((err: any) => {
        showNotification({
          type: 'error',
          message: `${t('Common.Error')}: ${err.message ? err.message : 'Can not create video creative'}`,
        })
        setLoading(false)
      })
  }

  if (!visible) return <></>

  const props: UploadProps<{ fileId: string }> = {
    name: 'file',
    accept: 'video/mp4',
    maxCount: 1,
    multiple: false,
    action: `${config.uiBackendUrl}/video-creative/uploadVideo`,
    headers: { Authorization: `Bearer ${getToken()}` },
    onChange(info) {
      const { status } = info.file
      if (status === 'uploading') {
        // @ts-ignore
        setVideoFile(URL.createObjectURL(info.file.originFileObj))
      }
      if (status === 'done') {
        setUploadedVideoFileId(info.file.response?.fileId)
      } else if (status === 'error') {
        showNotification({ type: 'error', message: 'error' })
      }
    },
  }

  return (
    <Modal
      destroyOnClose={true}
      title={creative && creative.id ? t('CreativeForm.Edit creative') : t('CreativeForm.Add creative')}
      open={visible}
      // @ts-ignore
      onOk={e => form.submit(e)}
      onCancel={() => setVisible(false)}
      width={720}
      confirmLoading={isLoading}
    >
      <Player
        playsInline
        muted={true}
        autoPlay={true}
        fluid={true}
        height={200}
        // aspectRatio={'16:9'}
        // @ts-ignore
        src={videoFile}
      />
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onFinish} initialValues={initialValues} {...formItemLayout}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ marginBottom: '0px' }} />
            </p>
            <p className="ant-upload-text" style={{ margin: '0px' }}>
              Click or drag file to this area to upload
            </p>
          </Dragger>
          <Form.Item
            style={{ paddingTop: '10px' }}
            name="title"
            label={t('Creative.Title')}
            rules={[{ required: true, max: 40 }]}
          >
            <Input size="small" />
          </Form.Item>

          <Form.Item
            name="tags"
            label={<LabelWithTooltip label={t('Creative.Tags')} labelTooltip={t('Creative.Tags Help')} />}
          >
            <Select
              mode="tags"
              allowClear
              // @ts-ignore
              options={creativesTags.map(tag => ({
                label: tag,
                value: tag,
              }))}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
