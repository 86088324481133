import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const report = c.router({
  getBidReport: {
    method: 'GET',
    path: '/report.getBidReport',
    responses: {
      200: z.object({
        items: z.array(
          z.object({
            campaignId: z.number(),
            previousBid: z.number(),
            bid: z.number(),
            capBid: z.number(),
            floorBid: z.number(),
            createdAt: z.any(),
            cost: z.number(),
            countConversion: z.number(),
          }),
        ),
      }),
    },
    query: z.object({
      campaignId: z.coerce.number(),
    }),
  },

  getAdvertiserSummaryReport: {
    method: 'GET',
    path: '/report.getAdvertiserSummaryReport',
    responses: {
      200: z.object({
        costToday: z.number(),
        costYesterday: z.number(),
        cost7days: z.number(),
        costThisMonth: z.number(),
        clicksToday: z.number(),
        clicksYesterday: z.number(),
        clicks7days: z.number(),
        clicksThisMonth: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getPublisherSummaryReport: {
    method: 'GET',
    path: '/report.getPublisherSummaryReport',
    responses: {
      200: z.object({
        costToday: z.number(),
        costYesterday: z.number(),
        cost7days: z.number(),
        costThisMonth: z.number(),
        clicksToday: z.number(),
        clicksYesterday: z.number(),
        clicks7days: z.number(),
        clicksThisMonth: z.number(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
